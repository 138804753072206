import React, {useState, useEffect} from 'react'
import './App.css';
import Home from './Screens/Home/Home';

import {BrowserRouter, Routes, Route, useLocation} from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";

import Oferta from './Screens/Oferta/Oferta';
import Privacy from './Screens/Privacy/Privacy';
import Form from './Screens/Form/Form';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';


export function ScrollToTops(props) {
    const {pathname} = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return props.children
}
function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {width, height};
}

export function useWindowDimensions() {
    const [windowDimensions,
        setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

function App() {
    return (
        <BrowserRouter>
            <ScrollToTops>
                <div className="App">
                    <Header />
                    <Routes>
                        <Route path="/" element={<Home />} exact/>
                        <Route path="/oferta" element={<Oferta />} exact/>
                        <Route path="/privacy" element={<Privacy />} exact/>
                        <Route path="/form" element={<Form />} exact/>
                    </Routes>
                    <Footer />
                    <ScrollToTop smooth />
                </div>
            </ScrollToTops>
        </BrowserRouter>
    );
}

export default App;
