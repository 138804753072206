import React, {useEffect} from 'react'
import Container from '../../Components/Container/Container';
import Title from '../../Components/Title/Title';
import Slider from "react-slick";

import './ColorsShop.css'
import { useWindowDimensions } from '../../App';

const OpportunitiesItems = ({items}) => {
    return (
        <div className='colors-shop__block-opportunities-items'>
            {items.map((item, i) => (
                <div key={i} className='colors-shop__block-opportunities-items-item'>
                    <img className='colors-shop__block-opportunities-items-item-img' src={require(`../../Assets/UniqueDesign/${item.img}.svg`)} alt='opportunities_icon'/>
                    <div className='colors-shop__block-opportunities-items-item-title'>{item.title}</div>
                </div>
            ))}
        </div>
    )
}

export const ColorsShopIntro = () => {
    useEffect(() => {
        var square = document.querySelector('.colors-shop__block');
    
        var observer = new IntersectionObserver(entries => {
          entries.forEach(entry => {
            var entrySquare = entry.target.querySelector('.colors-shop_img-wrapper');
    
            if (entry.isIntersecting) {
              entrySquare.classList.add('colors-shop_img-wrapper--animation');
              return;
            }
    
            entrySquare.classList.remove('colors-shop_img-wrapper--animation');
          });
        });
    
        observer.observe(square);

        var observerBlock = new IntersectionObserver(entries => {
            entries.forEach(entry => {
              var entrySquare = entry.target.querySelector('.colors-shop__block-opportunities');
      
              if (entry.isIntersecting) {
                entrySquare.classList.add('colors-shop__block-opportunities--animation');
                return;
              }
      
              entrySquare.classList.remove('colors-shop__block-opportunities--animation');
            });
          });
      
          observerBlock.observe(square);
      }, []);
    return (
        <div className="colors-shop">
            <Container>
                <Title>Создайте свой <span className="marker">уникальный</span> магазин для любого товара!</Title>
                <div className='colors-shop__block'>
                    <div className='colors-shop__entrance'>
                        <div className="colors-shop_img-wrapper">
                            <img className='two-phones' src={require("../../Assets/two-phones.webp")} alt="two-phones-img" />
                        </div>
                    </div>
                    <div className='colors-shop__block-opportunities'>
                        <div className='colors-shop__block-opportunities-title'>Возможности TGMALL:</div>
                        <OpportunitiesItems items={[
                            { img: 'package', title: 'Категории и товары' },
                            { img: 'wallet', title: 'Варианты оплаты' },
                            { img: 'chat', title: 'Заказы клиентов прямо в чате' },
                            { img: 'shop', title: 'Корзина' },
                            { img: 'switch', title: 'Управление магазином' },
                            { img: 'chart', title: 'Статистика заказов' },
                            { img: 'info', title: 'Оповещение клиентов' },
                            { img: 'bus', title: 'Способы доставки' },
                        ]} />
                        <div className='colors-shop__block-opportunities-definition'>
                            <span className='colors-shop__block-opportunities-definition-underline'>TGMALL</span> - готовое решение для открытия вашего собственного магазина под любую нишу.
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}

const ColorsShop = () => {
    const { width } = useWindowDimensions();

    var settings = {
        customPaging: function (i) {
            return (
                <div className={`colors-dot colors-dot--${i + 1}`} />
            );
          },
          appendDots: dots => (
            <div>
                <div className="colors-dots">{dots}</div>
            </div>
          ),
        nextArrow: <img className='arrow' src={require('../../Assets/Clients/right-arrow.svg').default} alt="arrow" />,
        prevArrow: <img className='arrow' src={require('../../Assets/Clients/left-arrow.svg').default} alt="arrow" />,
        autoplay: true,
        draggable: true,
        autoplaySpeed: 3500,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: width <= 800 ? 1 : 3,
        slidesToScroll: 1
      };
  
    return (
        <div className="colors-shop">
            <Title>Попробуйте демо<span className="marker">!</span></Title>
            <div id="demo" className="scroller">
                <Container>
                    <Slider {...settings}>
                        <div className="carousel-slide_wrapper">
                            <img className="carousel-slide" src={require('../../Assets/PhoneSlides/green.webp')} alt="phone-color" />
                            {width > 1050 ? <img className='qrcode' src={require("../../Assets/qr-code.webp")} alt="code" /> : null}
                            <a href='https://t.me/tgmall_demo1_bot' target="_blank" rel="noreferrer" className="text-block_telegram__btn text-block_telegram__btn--m link">
                                Открыть демо доставка цветов
                                {width > 1050 ? <img className="text-block_telegram__next" src={require("../../Assets/Icons/next.svg").default} alt="next" /> : null }
                            </a>
                        </div>
                        <div className="carousel-slide_wrapper">
                            <img className="carousel-slide" src={require('../../Assets/PhoneSlides/blue.webp')} alt="phone-color" />
                            {width > 1050 ? <img className='qrcode' src={require("../../Assets/qr-code1.webp")} alt="code" /> : null}
                            <a href='https://t.me/tgmall_demo3_bot' target="_blank" rel="noreferrer" className="text-block_telegram__btn text-block_telegram__btn--m link">
                                Открыть демо магазин телефонов
                                {width > 1050 ? <img className="text-block_telegram__next" src={require("../../Assets/Icons/next.svg").default} alt="next" /> : null }
                            </a>
                        </div>
                        <div className="carousel-slide_wrapper">
                            <img className="carousel-slide" src={require('../../Assets/PhoneSlides/orange.webp')} alt="phone-color" />
                            {width > 1050 ? <img className='qrcode' src={require("../../Assets/qr-code2.webp")} alt="code" /> : null}
                            <a href='https://t.me/tgmall_demo2_bot' target="_blank" rel="noreferrer" className="text-block_telegram__btn text-block_telegram__btn--m link">
                                Открыть демо доставка роллов 
                                {width > 1050 ? <img className="text-block_telegram__next" src={require("../../Assets/Icons/next.svg").default} alt="next" /> : null }
                            </a>
                        </div>
                    </Slider>
                </Container>
            </div>
        </div>
    );
}

export default ColorsShop;