import React, {useState, useEffect} from 'react';
import ReactPlayer from 'react-player'
import Container from '../../Components/Container/Container';
import { isMobile } from 'react-device-detect';
import myVideo from './video.MP4'
import { HashLink } from 'react-router-hash-link';
import './HomeVideo.css';

const HomeVideo = () => {
    const [playing, setPlaying] = useState(true);
    const [showImg, setShowImg] = useState(true);

    const handlePlayPause = () => {
        setPlaying(!playing);
    }

    useEffect(() => {
        var square = document.querySelector('.home_video');
    
        var observerTitle = new IntersectionObserver(entries => {
          entries.forEach(entry => {
            var entrySquare = entry.target.querySelector('.text-block_text');
    
            if (entry.isIntersecting) {
              entrySquare.classList.add('text-block_text--animation');
              return;
            }
    
            entrySquare.classList.remove('text-block_text--animation');
          });
        });

        var observerSub = new IntersectionObserver(entries => {
            entries.forEach(entry => {
              var entrySquare = entry.target.querySelector('.text-block_sub-text');
      
              if (entry.isIntersecting) {
                entrySquare.classList.add('text-block_sub-text--animation');
                return;
              }
      
              entrySquare.classList.remove('text-block_sub-text--animation');
            });
          });
    
        observerTitle.observe(square);
        observerSub.observe(square);
      }, []);

    return (
        <div className="home_video">
                <Container>
                <div className="home_video-blocks">
                        <div className="home_video__text-block">
                            <h1 className="text-block_text">
                                Откройте свой интернет-магазин в <a href='https://t.me/tgmall_demo1_bot' target="_blank" rel="noreferrer" className="text-block_text--badge link">Telegram</a> за 1 день
                            </h1>
                            <h2 className='text-block_sub-text'>
                            <b style={{color: '#007AFF'}}>Продавайте</b> ваши товары в лучшем мессенджере России. <b style={{color: '#007AFF'}}>Зарабатывайте</b> больше ваших конкурентов, используя новый канал продаж.
                            </h2>
                            <div className="text-block_telegram">
                            {/* <CostBlock /> */}
                                <div className="text-block_telegram__btn-wrapper">
                                    <a href='#demo' className="text-block_telegram__btn link">Посмотреть демо магазины <img className="text-block_telegram__next" src={require("../../Assets/Icons/next.svg").default} alt="next" /></a>
                                    <HashLink to="/#contact" className="animation text-block_telegram__btn text-block_telegram__btn--create link">
                                        <span>Создать магазин</span>
                                    </HashLink>
                                </div>
                            </div>
                        </div>
                        <div className="home_video__video-block_wrapper">
                            <div className="home_video__video-block">
                                <img onClick={() => handlePlayPause()} className='mockup' src={require("../../Assets/mockup.webp")} alt="mockup" />
                                {showImg ?
                                    <img className='preview-img' src={require('../../Assets/Home1.webp')} alt="onload"/>
                                : null}
                                <ReactPlayer 
                                onStart={() => setShowImg(false)}
                                id="myVideo"
                                attr="aaa"
                                autoPlay
                                playsinline
                                playing={playing}
                                loop 
                                className='gif'
                                muted
                                url={myVideo}  />
                            </div>
                            {isMobile ? 
                                <div className="mobile-btns">
                                <div className="text-block_telegram__play link" onClick={handlePlayPause}>
                                    {playing ? <img className="play_icon" src={require("../../Assets/Icons/pause.svg").default} alt="pause" /> : <img className="play_icon" src={require("../../Assets/Icons/next.svg").default} alt="next" />}
                                </div>
                                <HashLink to="/#contact" className="text-block_telegram__play text-block_telegram__play--create-shop link">Создать магазин</HashLink>
                                </div>
                            : null}
                        </div>
                    </div>
                </Container>
            </div>
    );
}

export default HomeVideo;