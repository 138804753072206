import React from 'react'
import Clients from '../../Blocks/Clients/Clients';
import ColorsShop, { ColorsShopIntro } from '../../Blocks/ColorsShop/ColorsShop';
import Connect from '../../Blocks/Connect/Connect';
import ControlShop from '../../Blocks/ControlShop/ControlShop';
// import Cost from '../../Blocks/Cost/Cost';
import { CostTariff } from '../../Blocks/CostTariff/CostTariff';
import Customization from '../../Blocks/Customization/Customization';
import DeliveryPayment from '../../Blocks/DeliveryPayment/DeliveryPayment';
import { FAQ } from '../../Blocks/FAQ/FAQ';
import FastSale from '../../Blocks/FastSale/FastSale';
import HomeVideo from '../../Blocks/HomeVideo/HomeVideo';
import OrderInfo from '../../Blocks/OrderInfo/OrderInfo';
import SaveBusiness from '../../Blocks/SaveBusiness/SaveBusiness';
import {Helmet} from "react-helmet";
import './Home.css'

const Home = () => {
    return (
        <div className="home">
            <Helmet>
                <meta charSet="utf-8" />
                <title>TGMALL</title>
                <link rel="canonical" href="https://tgmall.ru/" />
            </Helmet>
            <HomeVideo />
            {/* <SquareWrapper /> */}
            <ColorsShopIntro />
            <SaveBusiness />
            <Customization />
            <ColorsShop />
            <DeliveryPayment />
            <FastSale />
            <OrderInfo />
            <ControlShop />
            <Clients />
            <CostTariff />
            {/* <Cost /> */}
            <FAQ />
            <Connect />
        </div>
    );
}

export default Home;