import React, {useEffect} from 'react'
import Container from '../../Components/Container/Container';
import Title, { Subtitle } from '../../Components/Title/Title';
import './SaveBusiness.css'

export const SaveBusinessCard = ({items}) => {
    useEffect(() => {
        var square = document.querySelector('.save-bussines');
    
        var observer = new IntersectionObserver(entries => {
          entries.forEach(entry => {
            var entrySquare = entry.target.querySelector('.save-bussiness__cards');
    
            if (entry.isIntersecting) {
              entrySquare.classList.add('paralax--animation');
              return;
            }
    
            entrySquare.classList.remove('paralax--animation');
          });
        });
    
        observer.observe(square);
      }, []);
    return (
        <div className="save-bussiness__cards">
            {items.map((item, index) => (
                <div key={index} className="paralax">
                    <a target="_blank"></a>
                    <a target="_blank"></a>
                    <a target="_blank"></a>
                    <a target="_blank"></a>
                    <div className="save-bussiness__cards--item paralax__card">
                        <img className='save-bussiness__cards-img' src={require(`../../Assets/Icons/SaveBusinessIcons/${item.img}.svg`)} alt="cards-img" />
                        <div className="save-business__cards--item-text">{item.title}</div>
                    </div>
                </div>
            ))}
        </div>
    );
}

const SaveBusiness = () => {
    return (
        <Container>
            <div className="save-bussines">
                <Title><span className='marker'>TGMALL</span> подойдет для:</Title>
                <SaveBusinessCard items={[
                    {
                        img: "shop",
                        title: "Владельцев магазинов",
                    },
                    {
                        img: "inst",
                        title: "Владельцев Instagram* аккаунтов",
                    },
                    {
                        img: "cafe-rest",
                        title: "Владельцев кафе и ресторанов",
                    },
                    {
                        img: "partners",
                        title: "Частных бизнесменов и предпринимателей",
                    },
                    {
                        img: "think",
                        title: "Тех, кто хочет протестировать новую нишу",
                    },
                    {
                        img: "hands",
                        title: "Тех, кто предоставляет услуги",
                    },
                    ]} />
                <div className="save-business__undertext">* Запрещенная в РФ организация</div>
            </div>
        </Container>
    );
}

export default SaveBusiness;