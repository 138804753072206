import React, {useState, useCallback, useRef} from 'react'
import Container from '../../Components/Container/Container';
import { useNavigate, Link } from "react-router-dom";
import FileBase64 from 'react-file-base64';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { animateScroll as scroll } from 'react-scroll'
import validator from 'validator' 
import 'react-notifications/lib/notifications.css';
import './Form.css'
import {Helmet} from "react-helmet";
import Modal from '../../Components/Modal/Modal';

const Form = () => {
    const navigate = useNavigate();
    // -- 1 STEP --
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [city, setCity] = useState('');
    // -- 2 STEP --
    const [nameOfCompany, setNameOfCompany] = useState('');
    const [inn, setInn] = useState('');
    const [addressCompany, setAddressCompany] = useState('');
    // 2 STEP RADIO
    const [radioFormReg, setRadioFormReg] = useState('');
    // -- 3 STEP --
    const [nameOfShop, setNameOfShop] = useState('');
    const [nameOfLink, setNameOfLink] = useState('');
    const [descriptionShop, setDescriptionShop] = useState('');
    const [helloMsg, setHelloMsg] = useState('');
    const [another, setAnother] = useState('');
    const [showAnother, setShowAnother] = useState(false);
    // 3 STEP RADIO
    const [radioFormBtnName, setRadioFormBtnName] = useState('');
    // 3 STEP IMG
    const [firstImg, setFirstImg] = useState([])
    const [secondImg, setSecondImg] = useState([]);
    const [thirdImg, setThirdImg] = useState([]);

    // -- 4 STEP --
    const [anotherColor, setAnotherColor] = useState('');
    const [showAnotherColor, setShowAnotherColor] = useState(false);
    const [aboutDel, setAboutDel] = useState('');
    const [aboutCompany, setAboutCompany] = useState('');
    const [priceDelivery, setPriceDelivery] = useState('');
    const [freeStage, setFreeStage] = useState('');
    // 4 STEP CHECKBOX
    const [userinfo, setUserInfo] = useState({
        delivMethod: [],
        response: [],
    });
    const [info, setPayMethod] = useState({
        payMethod: [],
        response: [],
    });
    const [anotherMethodDel, setAnotherMethodDel] = useState('');
    const [numberPhoneCard, setNumberPhoneCard] = useState('');
    const [nameOfMoneyReciever, setNameOfMoneyReciever] = useState('');
    const [showAnotherMethodDel, setShowAnotherMethodDel] = useState(false);
    const [checkBoxAgree, setCheckBoxAgree] = useState('');
    const [showDeliveryBlock, setShowDeliveryBlock] = useState(false);
    const [showExtraPayDelivery, setShowExtraPayDelivery] = useState(false);
    const [showMethodPayBlock, setShowMethodPayBlock] = useState(false);
    // 4 STEP RADIO
    const [categoryChoose, setCategoryChoose] = useState('');
    const [radioFormColor, setRadioFormColor] = useState('');
    const [ostatkiChoose, setOstatkiChoose] = useState('');
    const [payDelivery, setPayDelivery] = useState('');
    const [radioFormBank, setRadioFormBank] = useState('');

    // STEPS SHOW
    const [show1Step, setShow1Step] = useState(true);
    const [show2Step, setShow2Step] = useState(false);
    const [show3Step, setShow3Step] = useState(false);
    const [show4Step, setShow4Step] = useState(false);

    // 1 STEP
    const inputRefPhone = useRef(null);
    const inputRefName = useRef(null);
    const inputRefCity = useRef(null);
    // 2 STEP
    const inputRefNameOfCompany = useRef(null);
    const inputRefInn = useRef(null);
    const inputRefAddressCompany = useRef(null);
    
    const [showModal, setShowModal] = useState(false);
    const [isFormSent, setIsFormSent] = useState(false);
 

    // FUNCTIONS
    // info@drivery.org 
    const SendForm = (name, phone, city, radioFormReg, nameOfCompany, inn, addressCompany, nameOfShop, nameOfLink, descriptionShop, radioFormBtnName, helloMsg, radioFormColor, anotherColor, aboutDel, aboutCompany, userinfo, info, anotherMethodDel, radioFormBank, checkBoxAgree, firstImg, secondImg, thirdImg, categoryChoose, ostatkiChoose, numberPhoneCard, nameOfMoneyReciever) => {
        window.Email.send({
            SecureToken : "43a22622-b674-4744-99f2-c67be48028b8",
            To : 'info@drivery.org',
            From : "tgmall@tgmall.ru",
            Subject : "🔵 TGMALL (✅Заявка на открытие магазина TGMALL✅)",
            Body : `
            <table border="1">
            <tbody>
              <tr>
                <td>Информация о заказчике:</td>
              </tr>
              <tr>
                <td>
                  <ul>
                    <li>ФИО: ${name}</li>
                    <li>Телефон: ${phone}</li>
                    <li>Город: ${city}</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Информация о предприятии:</td>
              </tr>
              <tr>
                <td>
                  <ul>
                    <li>Форма регистрации: ${radioFormReg}</li>
                    <li>Название предприятия: ${nameOfCompany}</li>
                    <li>ИНН: ${inn == "" ? "Не заполнено❌" : inn}</li>
                    <li>Адрес магазина/офиса: ${addressCompany == "" ? "Не заполнено❌" : addressCompany}</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Информация о магазине в Telegram:</td>
              </tr>
              <tr>
                <td>
                  <ul>
                    <li>Название вашего бота/магазина: ${nameOfShop}</li>
                    <li>Название ссылки на бота: ${nameOfLink}</li>
                    <li>Описание магазина/бота: ${descriptionShop}</li>
                    <li>Иконка магазина: ${firstImg.name ?? "Не заполнено❌"}</li>
                    <li>Название нижней кнопки для открытия магазина: ${radioFormBtnName}</li>
                    <li>Приветственное сообщение: ${helloMsg}</li>
                    <li>Приветственная картинка ${secondImg.name ?? "Не заполнено❌"}</li>
                    <li>Логотип магазина: ${thirdImg.name ?? "Не заполнено❌"}</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Настройки магазина:</td>
              </tr>
              <tr>
                <td>
                  <ul>
                    <li>Шаблон магазина: ${categoryChoose}</li>
                    <li>Цвет кнопок магазина: ${radioFormColor}</li>
                    <li>Цвет кнопок магазина (Другое): ${anotherColor == "" ? "Нет❌" : anotherColor}</li>
                    <li>Остатки оставить?: ${ostatkiChoose}</li>
                    <li>Информационный текст для кнопки "О доставке": ${aboutDel == "" ? "Не заполнено❌" : aboutDel}</li>
                    <li>Информационный текст для кнопки "О компании": ${aboutCompany == "" ? "Не заполнено❌" : aboutCompany}</li>
                    <li>Методы доставки: ${userinfo.delivMethod}</li>
                    <li>Метод доставки (Другое): ${anotherMethodDel == "" ? "Нет❌" : anotherMethodDel}</li>
                    <li>Курьерская доставка: доставка платная?: ${payDelivery == "" ? "Не выбрано" : payDelivery} <br />1) Стоимость доставки: ${priceDelivery == "" ? "Не выбрано" : priceDelivery}<br />2) Порог бесплатной доставки: ${freeStage == "" ? "Не выбрано" : freeStage}</li>
                    <li>Методы оплаты: ${info.payMethod}</li>
                    <li>Настройка перевода на карту по номеру телефона: <br />1) Карта или номер телефона: ${numberPhoneCard == "" ? "Не выбрано" : numberPhoneCard}<br />2) Карта или номер телефона: ${nameOfMoneyReciever == "" ? "Не выбрано" : nameOfMoneyReciever}</li>
                    <li>Интернет эквайринг: ${radioFormBank == "" ? "Нет❌" : radioFormBank}</li>
                    <li>Политика конфиденциальности и Правила предоставления услуг: ${checkBoxAgree}</li>
                  </ul>
                </td>
              </tr>
            `,
            Attachments: 
            [
                {
                name: firstImg.name ?? '',
                data: firstImg.base64 ?? '',
                },
                {
                name: secondImg.name ?? '',
                data: secondImg.base64 ?? '',
                },
                {
                name: thirdImg.name ?? '',
                data: thirdImg.base64 ?? '',
                },
            ]
        }).then(
      message => console.log(message)
    );
    }
    
    // DROP IMAGE 2 STEP
    const getFiles1 = (files) => {
        if(files.size >= 2*1024*1024) {
            createNotification("maxSizeImg");
            setFirstImg([]);
            return;
        } else {
            setFirstImg(files);
        }
        if(!files.type.match('image/jp.*') && !files.type.match('image/pn.*')) {
            createNotification("onlyImg");
            setFirstImg([]);
            return;
        } else {
            setFirstImg(files);
        }

    }

    const getFiles2 = (files) => {
        if(files.size >= 2*1024*1024) {
            createNotification("maxSizeImg");
            setSecondImg([]);
            return;
        } else {
            setSecondImg(files);
        }
        if(!files.type.match('image/jp.*') && !files.type.match('image/pn.*')) {
            createNotification("onlyImg");
            setSecondImg([]);
            return;
        } else {
            setSecondImg(files);
        }
    }

    const getFiles3 = (files) => {
        if(files.size >= 2*1024*1024) {
            createNotification("maxSizeImg");
            setThirdImg([]);
            return;
        } else {
            setThirdImg(files);
        }
        if(!files.type.match('image/jp.*') && !files.type.match('image/pn.*')) {
            createNotification("onlyImg");
            setThirdImg([]);
            return;
        } else {
            setThirdImg(files);
        }
    }

    const removeSelectedFirstImage = () => {
        setFirstImg([]);
    };

    const removeSelectedSecondImage = () => {
        setSecondImg([]);
    };

    const removeSelectedThirdImage = () => {
        setThirdImg([]);
    };

    let isValidPhoneNumber;
    const validatePhoneNumber = (phone) => {
        isValidPhoneNumber = validator.isMobilePhone(phone)
        if (isValidPhoneNumber == false) {
            createNotification('phone');
        }
        return (isValidPhoneNumber)
    }

    var re = /[^[A-Za-zА-Яа-яёЁ]+(?:[-'\s][A-Za-zА-Яа-яёЁ]+)*$]/;
    const checkName = (name) => {
        if (name.length > 35) {
            createNotification('nameLimit');
            return false;
        }
        else if (re.test(name)) {
            createNotification('name');
            return false;
        }
        return true;
    }

    const notifCheck = () => {
        createNotification("checkAllInputs");
    }

    const checkAllInputs1Step = () => {
        if (phone !== "" && name !== "" && city !== "") {
            return true;
        }
        return false;
    }

    const checkAllInputs2Step = () => {
        if (nameOfCompany !== "" && radioFormReg !== '') {
            return true;
        }
        return false;
    }

    const checkAllInputs3Step = () => {
        if (nameOfShop !== "" && nameOfLink !== '' && descriptionShop !== "" && radioFormBtnName !== '' && helloMsg !== '') {
            return true;
        }
        return false;
    }

    const checkAllInputs4Step = () => {
        if (radioFormColor !== "" && userinfo.delivMethod.length !== 0 && info.payMethod.length !== 0 && checkBoxAgree !== "" && categoryChoose !== "" && ostatkiChoose !== "") {
            return true;
        }
        return false;
    }

    const checkDeliveryBlock = () => {
        if (showDeliveryBlock == false) {
            return true;
        }
        if (showDeliveryBlock == true && payDelivery === "Нет") {
            return true;
        } else if (showDeliveryBlock == true && payDelivery == "") {
            return false;
        }
        if (showDeliveryBlock == true && payDelivery == "Да" && priceDelivery !== "" && freeStage !== "") {
            return true;
        } 
        if (showDeliveryBlock == true && payDelivery == "Да" && priceDelivery === "" && freeStage === "") {
            return false;
        }
        return false;
    }

    const checkMethodPayBlock = () => {
        if (showMethodPayBlock == false) {
            return true;
        }
        if (showMethodPayBlock == true && numberPhoneCard == "") {
            return false;
        }
        if (showMethodPayBlock == true && nameOfMoneyReciever == "") {
            return false;
        }
        if (showMethodPayBlock == true && numberPhoneCard !== "" && nameOfMoneyReciever !== "") {
            return true;
        }
        return false;
    }


    const createNotification = (type) => {
        switch (type) {
          case 'phone':
            NotificationManager.info('Неправильно введен номер!');
            break;
          case 'next':
            NotificationManager.success('Отлично');
            break;
          case 'nameLimit':
            NotificationManager.info('Имя не должно превышать больше 35 символов!');
            break;
          case 'name':
            NotificationManager.info('Неправильно введено Имя!');
            break;
        case 'checkAllInputs':
            NotificationManager.info('Заполните все обязательные поля, помеченные * (звездочкой)!');
            break;
        case 'maxSizeImg':
            NotificationManager.info('Максимальный размер изображения 2Мб');
            break;
        case 'onlyImg':
            NotificationManager.info('Для загрузки допускаются только изображения .webp .jpg .jpeg');
            break;
        }
    };

    
    const clearForm = () => {
        const isSure = window.confirm(`Вы действительно хотите очистить форму?`);
        if (isSure) {
            scroll.scrollToTop();
            navigate(0);
        }
    }

    const submit1Step = (name, phone) => {
        if (validatePhoneNumber(phone) && checkName(name)) {
            setShow1Step(false);
            setShow2Step(true);
            scroll.scrollToTop();
        }
        return false;
    }

    const submit2Step = () => {
        setShow2Step(false);
        setShow3Step(true);
        scroll.scrollToTop();
    }

    const submit3Step = () => {
        setShow3Step(false);
        setShow4Step(true);
        scroll.scrollToTop();
    }

    const submitSend = (name, phone, city, radioFormReg, nameOfCompany, inn, addressCompany, nameOfShop, nameOfLink, descriptionShop, radioFormBtnName, helloMsg, radioFormColor, anotherColor, aboutDel, aboutCompany, userinfo, info, anotherMethodDel, radioFormBank, checkBoxAgree, firstImg, secondImg, thirdImg, categoryChoose, ostatkiChoose, numberPhoneCard, nameOfMoneyReciever) => {
        SendForm(name, phone, city, radioFormReg, nameOfCompany, inn, addressCompany, nameOfShop, nameOfLink, descriptionShop, radioFormBtnName, helloMsg, radioFormColor, anotherColor, aboutDel, aboutCompany, userinfo, info, anotherMethodDel, radioFormBank, checkBoxAgree, firstImg, secondImg, thirdImg, categoryChoose, ostatkiChoose, numberPhoneCard, nameOfMoneyReciever);
        setShowModal(true);
        setIsFormSent(true);
        scroll.scrollToTop();
    }

    const backTo1Step = () => {
        setShow1Step(true);
        setShow2Step(false);
        scroll.scrollToTop();
    }

    const backTo2Step = () => {
        setShow2Step(true);
        setShow3Step(false);
        scroll.scrollToTop();
    }

    const backTo3Step = () => {
        setShow3Step(true);
        setShow4Step(false);
        scroll.scrollToTop();
    }

    // CALLBACKS
    // 1 STEP
    const phoneChangeCallback = useCallback(
        (e) => {
            setPhone(e.target.value.replace(/[^\d]/g, ''));
        }, []
    );

    const nameChangeCallback = useCallback(
        (e) => {
            setName(e.target.value);
        }, []
    );

    const cityChangeCallback = useCallback(
        (e) => {
            setCity(e.target.value);
        }, []
    );

    // 2 STEP
    const nameOfCompanyChangeCallback = useCallback(
        (e) => {
            setNameOfCompany(e.target.value);
        }, []
    );

    const innChangeCallback = useCallback(
        (e) => {
            setInn(e.target.value);
        }, []
    );

    const addressCompanyChangeCallback = useCallback(
        (e) => {
            setAddressCompany(e.target.value);
        }, []
    );

    const radioFormRegButtonChange = useCallback(
        (e) => {
            setRadioFormReg(e.target.value);
        }, []
    );
   
    // 3 STEP
    const nameOfShopChangeCallback = useCallback(
        (e) => {
            setNameOfShop(e.target.value);
        }, []
    );

    const nameOfLinkChangeCallback = useCallback(
        (e) => {
            setNameOfLink(e.target.value);
        }, []
    );

    const descriptionShopChangeCallback = useCallback(
        (e) => {
            setDescriptionShop(e.target.value);
        }, []
    );

    const helloMsgChangeCallback = useCallback(
        (e) => {
            setHelloMsg(e.target.value);
        }, []
    );

    const anotherChangeCallback = useCallback(
        (e) => {
            setRadioFormBtnName(e.target.value)
            setAnother(e.target.value);
        }, []
    );

    const radioFormBtnNameButtonChange = useCallback(
        (e) => {
            setRadioFormBtnName(e.target.value);
        }, []
    );

    // 4 STEP
    const categoryChooseChange = useCallback(
        (e) => {
            setCategoryChoose(e.target.value);
        }, []
    );

    const radioFormColorButtonChange = useCallback(
        (e) => {
            setRadioFormColor(e.target.value);
        }, []
    );

    const ostatkiChooseChange = useCallback(
        (e) => {
            setOstatkiChoose(e.target.value);
        }, []
    );

    const numberPhoneCardChangeCallback = useCallback(
        (e) => {
            setNumberPhoneCard(e.target.value);
        }, []
    );

    const nameOfMoneyRecieverChangeCallback = useCallback(
        (e) => {
            setNameOfMoneyReciever(e.target.value);
        }, []
    );

    const anotherColorChangeCallback = useCallback(
        (e) => {
            setRadioFormColor(e.target.value)
            setAnotherColor(e.target.value);
        }, []
    );

    const { delivMethod } = userinfo;
    const checkboxFormMethodDelChange = (e) => {
        const { value, checked } = e.target;
        const { delivMethod } = userinfo;
        
        if (checked) {
            setUserInfo({
                delivMethod: [...delivMethod, value],
                response: [...delivMethod, value],
            });
        }
        
        else {
            setUserInfo({
                delivMethod: delivMethod.filter((e) => e !== value),
                response: delivMethod.filter((e) => e !== value),
            });
        }
    };

    const anotherMethodDelChangeCallback = useCallback(
        (e) => {
            setAnotherMethodDel(e.target.value);
        }, []
    );

    const payDeliveryChange = useCallback(
        (e) => {
            setPayDelivery(e.target.value);
        }, []
    );

    const priceDeliveryChangeCallback = useCallback(
        (e) => {
            setPriceDelivery(e.target.value);
        }, []
    );

    const freeStageChangeCallback = useCallback(
        (e) => {
            setFreeStage(e.target.value);
        }, []
    );

    const { payMethod } = info;
    const checkboxFormPayMethodChange = (e) => {
        const { value, checked } = e.target;
        const { payMethod } = info;

        if (checked) {
          setPayMethod({
            payMethod: [...payMethod, value],
            response: [...payMethod, value],
          });
        }
      
        else {
          setPayMethod({
            payMethod: payMethod.filter((e) => e !== value),
            response: payMethod.filter((e) => e !== value),
          });
        }
    };

    const radioFormBankChange = useCallback(
        (e) => {
            setRadioFormBank(e.target.value);
        }, []
    );

    const checkboxAgreeChange = useCallback(
        (e) => {
            setCheckBoxAgree(e.target.value);
        }, []
    );

    const aboutDelChangeCallback = useCallback(
        (e) => {
            setAboutDel(e.target.value);
        }, []
    );

    const aboutCompanyChangeCallback = useCallback(
        (e) => {
            setAboutCompany(e.target.value);
        }, []
    );

    return (
        <div>
        {showModal ? <Modal onPress={() => {setShowModal(false); navigate('/')}} /> : null}
            <Container>
        <div className="form">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Заявка на открытие магазина TGMALL</title>
                    <link rel="canonical" href="https://tgmall.ru/form" />
                </Helmet>
                <div className="form_title">Заявка на открытие магазина TGMALL</div>
                <div className="form_subtitle">Подробно заполните форму для оперативного создания магазина</div>
                {show2Step == false && show1Step == true ?
                <div>
                    <div className="form_block form_paragraph">Информация о заказчике:</div>
                    <div className="form_block form_block--active">
                        <div className="form_block__title">Ваше ФИО <span className='required'>*</span></div>
                        <input ref={inputRefName} defaultValue={name} onChange={nameChangeCallback} placeholder='Например: Иван Иванович Иванов' className="form_block__input" />
                    </div>
                    <div className="form_block">
                        <div className="form_block__title">Ваш контактный номер телефона <span className='required'>*</span></div>
                        <input type="tel" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" ref={inputRefPhone} defaultValue={phone} onChange={phoneChangeCallback} placeholder='Например: +0(000)000-00-00' className="form_block__input" />
                    </div>
                    <div className="form_block">
                        <div className="form_block__title">Город ведения деятельности (основной) <span className='required'>*</span></div>
                        <input ref={inputRefCity} defaultValue={city} onChange={cityChangeCallback} placeholder='Ваш ответ' className="form_block__input" />
                    </div>
                    <div className="form_button-block">
                        {checkAllInputs1Step() ?
                        <div onClick={() => submit1Step(name, phone)} className="form_button-block__next">Далее</div>
                        :
                        <div onClick={() => notifCheck()} className="form_button-block__next--no-data">Далее</div>
                        }
                        <div onClick={() => clearForm()} className="form_button-block__clear">Очистить форму</div>
                    </div>
                </div>
                : null}

                {show2Step ?
                <div>
                    <div className="form_block form_paragraph">
                        <b>Информация о предприятии</b>
                        <div>Укажите как можно больше информации о вашей деятельности</div>
                    </div>
                    <div className="form_block">
                    <div className="form_block__title">Форма регистрации <span className='required'>*</span></div>
                        <form className="form_block__radio-group" onChange={radioFormRegButtonChange}>
                        <label>
                            <input checked={radioFormReg === 'Юридическое лицо'} className='form_block__radio-group-item' type="radio" name="Форма Регистрации" value="Юридическое лицо" />Юридическое лицо
                        </label>
                        <label>
                            <input checked={radioFormReg === 'ИП'} className='form_block__radio-group-item' type="radio" name="Форма Регистрации" value="ИП" />ИП
                        </label>
                        <label>
                            <input checked={radioFormReg === 'Самозанятое лицо'} className='form_block__radio-group-item' type="radio" name="Форма Регистрации" value="Самозанятое лицо" />Самозанятое лицо
                        </label>
                        <label>
                            <input checked={radioFormReg === 'Физическое лицо'} className='form_block__radio-group-item' type="radio" name="Форма Регистрации" value="Физическое лицо" />Физическое лицо
                        </label>
                        </form>
                    </div>
                    <div className="form_block">
                        <div className="form_block__title">Название предприятия <span className='required'>*</span></div>
                        <input ref={inputRefNameOfCompany} defaultValue={nameOfCompany} onChange={nameOfCompanyChangeCallback} placeholder='Ваш ответ' className="form_block__input" />
                    </div>
                    <div className="form_block">
                        <div className="form_block__title">ИНН</div>
                        <input type="number" ref={inputRefInn} defaultValue={inn} onChange={innChangeCallback} placeholder='Ваш ответ' className="form_block__input" />
                    </div>
                    <div className="form_block">
                        <div className="form_block__title">Адрес магазина/офиса (если есть)</div>
                        <input ref={inputRefAddressCompany} defaultValue={addressCompany} onChange={addressCompanyChangeCallback} placeholder='Ваш ответ' className="form_block__input" />
                    </div>
                    <div className="form_button-block">
                        <div>
                            <div style={{marginRight: '10px'}} onClick={() => backTo1Step()} className="form_button-block__next">Назад</div>
                            {checkAllInputs2Step() ?
                            <div onClick={() => submit2Step()} className="form_button-block__next">Далее</div>
                            :
                            <div onClick={() => notifCheck()} className="form_button-block__next--no-data">Далее</div>
                            }
                        </div>
                        <div onClick={() => clearForm()} className="form_button-block__clear">Очистить форму</div>
                    </div>
                </div>
                : null}

                {show3Step ? 
                <div>
                    <div className="form_block form_paragraph">
                        Информация о магазине в Telegram
                    </div>

                    <div className="form_block">
                        <div className="form_block__title">Название вашего бота/магазина <span className='required'>*</span></div>
                        <div className="form_block--img">
                            <img className='form_block__img' src={require("../../Assets/Form/nameShop.webp")} alt="nameShop" />
                        </div>
                        <input ref={inputRefNameOfCompany} defaultValue={nameOfShop} onChange={nameOfShopChangeCallback} placeholder='Ваш ответ' className="form_block__input" />
                    </div>
                    <div className="form_block">
                        <div className="form_block__title">Название ссылки на бота (добавьте название на английском языке. Название ссылки должно заканчиваться на "bot" или "_bot". Добавьте несколько вариантов названий на случай, если такое название уже занято), через запятую <span className='required'>*</span></div>
                        <div className="form_block--img">
                            <img className='form_block__img' src={require("../../Assets/Form/linkName.webp")} alt="linkName" />
                        </div>
                        <input ref={inputRefNameOfCompany} defaultValue={nameOfLink} onChange={nameOfLinkChangeCallback} placeholder='Ваш ответ' className="form_block__input" />
                    </div>
                    <div className="form_block">
                        <div className="form_block__title">Описание магазина/бота <span className='required'>*</span></div>
                        <div className="form_block--img">
                            <img className='form_block__img' src={require("../../Assets/Form/descriptionShop.webp")} alt="descriptionShop" />
                        </div>
                        <input ref={inputRefNameOfCompany} defaultValue={descriptionShop} onChange={descriptionShopChangeCallback} placeholder='Ваш ответ' className="form_block__input" />
                    </div>
                    <div className="form_block form_block--mb">
                        <div className="form_block__title">Иконка магазина (можно добавить позже)</div>
                        <div className="form_block--img">
                            <img className='form_block__img' src={require("../../Assets/Form/logoShop.webp")} alt="logoShop" />
                        </div>

                            <div id="form-id" className='image-uploader'>
                                <FileBase64
                                multiple={ false }
                                accept=".jpg,.jpeg,.png"
                                onDone={getFiles1} />
                                 {firstImg.file && (
                                <div className='image-uploader_img-wrapper'>
                                    <img
                                    className='image-uploader_img'
                                    src={URL.createObjectURL(firstImg.file)}
                                    alt="Thumb"
                                    />
                                    <div className='image-uploader_btn-rmv' onClick={removeSelectedFirstImage}>
                                    X
                                    </div>
                                </div>
                                )}
                            </div>

                    </div>
                    <div className="form_block">
                    <div className="form_block__title">Название нижней кнопки для открытия магазина <span className='required'>*</span></div>
                        <div className="form_block--img">
                            <img className='form_block__img' src={require("../../Assets/Form/button.webp")} alt="descriptionShop" />
                        </div>
                        <form className="form_block__radio-group" onChange={radioFormBtnNameButtonChange}>
                        <label>
                            <input checked={radioFormBtnName === 'Магазин'} className='form_block__radio-group-item' type="radio" name="Форма имени" value="Магазин" />Магазин
                        </label>
                        <label>
                            <input checked={radioFormBtnName === 'Каталог'} className='form_block__radio-group-item' type="radio" name="Форма имени" value="Каталог" />Каталог
                        </label>
                        <label>
                            <input checked={radioFormBtnName === 'Витрина'} className='form_block__radio-group-item' type="radio" name="Форма имени" value="Витрина" />Витрина
                        </label>
                        <label>
                            <input checked={radioFormBtnName === 'Меню'} className='form_block__radio-group-item' type="radio" name="Форма имени" value="Меню" />Меню
                        </label>
                        <label>
                            <input checked={radioFormBtnName === 'Заказать'} className='form_block__radio-group-item' type="radio" name="Форма имени" value="Заказать" />Заказать
                        </label>
                        <label>
                            <input checked={radioFormBtnName === 'Купить'} className='form_block__radio-group-item' type="radio" name="Форма имени" value="Купить" />Купить
                        </label>
                        <label>
                            <input checked={radioFormBtnName === another && another != ''} onClick={() => setShowAnother(true)} className='form_block__radio-group-item' type="radio" name="Форма имени" value={another} />Другое
                            {showAnother ?
                            <input defaultValue={another} onChange={anotherChangeCallback} placeholder='Ваш ответ' className="form_block__input" />
                            :null}
                        </label>
                        </form>
                    </div>
                    <div className="form_block">
                        <div className="form_block__title">Приветственное сообщение <span className='required'>*</span></div>
                        <div className="form_block--img">
                            <img className='form_block__img' src={require("../../Assets/Form/helloMsg.webp")} alt="descriptionShop" />
                        </div>
                        <input ref={inputRefNameOfCompany} defaultValue={helloMsg} onChange={helloMsgChangeCallback} placeholder='Ваш ответ' className="form_block__input" />
                    </div>
                    <div className="form_block form_block--mb">
                        <div className="form_block__title">Приветственная картинка (можно добавить позже)</div>
                        <div className="form_block--img">
                            <img className='form_block__img' src={require("../../Assets/Form/helloImg.webp")} alt="logoShop" />
                        </div>
                        
                        <div className='image-uploader'>
                                <FileBase64
                                multiple={ false }
                                onDone={getFiles2} />
                                 {secondImg.file && (
                                <div className='image-uploader_img-wrapper'>
                                    <img
                                    className='image-uploader_img'
                                    src={URL.createObjectURL(secondImg.file)}
                                    alt="Thumb"
                                    />
                                    <div className='image-uploader_btn-rmv' onClick={removeSelectedSecondImage}>
                                    X
                                    </div>
                                </div>
                                )}
                            </div>

                    </div>
                    <div className="form_block form_block--mb">
                        <div className="form_block__title">Логотип магазина (можно добавить позже)</div>
                        <div className="form_block--img">
                            <img className='form_block__img' src={require("../../Assets/Form/logoShop2.webp")} alt="logoShop2" />
                        </div>
                        
                        <div className='image-uploader'>
                                <FileBase64
                                multiple={ false }
                                onDone={getFiles3} />
                                 {thirdImg.file && (
                                <div className='image-uploader_img-wrapper'>
                                    <img
                                    className='image-uploader_img'
                                    src={URL.createObjectURL(thirdImg.file)}
                                    alt="Thumb"
                                    />
                                    <div className='image-uploader_btn-rmv' onClick={removeSelectedThirdImage}>
                                    X
                                    </div>
                                </div>
                                )}
                            </div>
                    </div>

                    <div className="form_button-block">
                        <div>
                            <div style={{marginRight: '10px'}} onClick={() => backTo2Step()} className="form_button-block__next">Назад</div>
                            {checkAllInputs3Step() ?
                            <div onClick={() => submit3Step()} className="form_button-block__next">Далее</div>
                            :
                            <div onClick={() => notifCheck()} className="form_button-block__next--no-data">Далее</div>
                            }
                        </div>
                        <div onClick={() => clearForm()} className="form_button-block__clear">Очистить форму</div>
                    </div>
                </div>
                : null}

                {show4Step ?
                <div>
                <div className="form_block form_paragraph">
                    Настройки магазина
                </div>
                <div className="form_block">
                <div className="form_block__title">Выберите шаблон <span className='required'>*</span></div>
                        <div className="form_block--img">
                            <img className='form_block__img form_block__img--category' src={require("../../Assets/Form/category-page.webp")} alt="category-page" />
                            <img className='form_block__img form_block__img--category' src={require("../../Assets/Form/category-inline.webp")} alt="category-inline" />
                        </div>
                        <form className="form_block__radio-group form_block__radio-group--category" onChange={categoryChooseChange}>
                            <label>
                                <input checked={categoryChoose === 'Категории-страница'} className='form_block__radio-group-item' type="radio" name="Категории-страница" value="Категории-страница"/>Категории товаров <b>в виде карточек</b> на главной странице, (предпочтительно для большого количества товаров) 
                            </label>
                            <label>
                                <input checked={categoryChoose === 'Категории-строка'} className='form_block__radio-group-item' type="radio" name="Категории-строка" value="Категории-строка"/>Категории товаров на всех страницах <b>в виде строки</b>, (для небольшого количества товаров)
                            </label>
                    </form>   
                </div>
                <div className="form_block">
                <div className="form_block__title">Цвет кнопок магазина <span className='required'>*</span></div>
                    <div className="form_block--img">
                            <img className='form_block__img' src={require("../../Assets/Form/color-btns.webp")} alt="logoShop2" />
                        </div>
                        <div style={{marginBottom: '10px'}}>Выберите цвет:</div>
                    <form className='form_block-radio' onChange={radioFormColorButtonChange}>
                        <label>
                            <input checked={radioFormColor === 'Красный'} type="radio" name="Цвет" value="Красный"/>
                            <img src={require("../../Assets/Form/colors/Красный.webp")} alt="Option 1"/>
                        </label>
                        <label>
                            <input checked={radioFormColor === 'Оранжевый'} type="radio" name="Цвет" value="Оранжевый"/>
                            <img src={require("../../Assets/Form/colors/Оранжевый.webp")} alt="Option 2"/>
                        </label>
                        <label>
                            <input checked={radioFormColor === 'Желтый'} type="radio" name="Цвет" value="Желтый"/>
                            <img src={require("../../Assets/Form/colors/Желтый.webp")} alt="Option 3"/>
                        </label>
                        <label>
                            <input checked={radioFormColor === 'Зеленый'} type="radio" name="Цвет" value="Зеленый"/>
                            <img src={require("../../Assets/Form/colors/Зеленый.webp")} alt="Option 1"/>
                        </label>
                        <label>
                            <input checked={radioFormColor === 'Голубой'} type="radio" name="Цвет" value="Голубой"/>
                            <img src={require("../../Assets/Form/colors/Голубой.webp")} alt="Option 2"/>
                        </label>
                        <label>
                            <input checked={radioFormColor === 'Синий'} type="radio" name="Цвет" value="Синий"/>
                            <img src={require("../../Assets/Form/colors/Синий.webp")} alt="Option 3"/>
                        </label>
                        <label>
                            <input checked={radioFormColor === 'Фиолетовый'} type="radio" name="Цвет" value="Фиолетовый"/>
                            <img src={require("../../Assets/Form/colors/Фиолетовый.webp")} alt="Option 3"/>
                        </label>
                        <label>
                            <input checked={radioFormColor === 'Розовый'} type="radio" name="Цвет" value="Розовый"/>
                            <img src={require("../../Assets/Form/colors/Розовый.webp")} alt="Option 3"/>
                        </label>
                        <label>
                            <input checked={radioFormColor === 'Маджента'} type="radio" name="Цвет" value="Маджента"/>
                            <img src={require("../../Assets/Form/colors/Маджента.webp")} alt="Option 3"/>
                        </label>
                        <label>
                            <input checked={radioFormColor === 'Черный'} type="radio" name="Цвет" value="Черный"/>
                            <img src={require("../../Assets/Form/colors/Черный.webp")} alt="Option 3"/>
                        </label>
                    </form>
                    <label>
                        <input checked={radioFormColor === anotherColor} onClick={() => setShowAnotherColor(true)} className='form_block__radio-group-item' type="radio" name="Цвет" value={anotherColor} />Другое
                        {showAnotherColor ?
                        <input defaultValue={anotherColor} onChange={anotherColorChangeCallback} placeholder='Ваш ответ' className="form_block__input" />
                        :null}
                    </label>
                </div>
                <div className="form_block">
                <div className="form_block__title">Отображать количество остатков в товарах? <span className='required'>*</span></div>
                        <div className="form_block--img">
                            <img className='form_block__img' src={require("../../Assets/Form/ostatki.webp")} alt="ostatki" />
                        </div>
                    <form className="form_block__radio-group" onChange={ostatkiChooseChange}>
                        <label>
                            <input checked={ostatkiChoose === 'Да'} className='form_block__radio-group-item' type="radio" name="Да" value="Да"/>Да
                        </label>
                        <label>
                            <input checked={ostatkiChoose === 'Нет'} className='form_block__radio-group-item' type="radio" name="Нет" value="Нет"/>Нет
                        </label>
                    </form>   
                </div>
                <div className="form_block">
                    <div className="form_block__title">Добавьте информационный текст для кнопки "О доставке" (Можно позже)</div>
                    <input defaultValue={aboutDel} onChange={aboutDelChangeCallback} placeholder='Ваш ответ' className="form_block__input" />
                </div>
                <div className="form_block">
                    <div className="form_block__title">Добавьте информационный текст для кнопки "О компании" (Можно позже)</div>
                    <input defaultValue={aboutCompany} onChange={aboutCompanyChangeCallback} placeholder='Ваш ответ' className="form_block__input" />
                </div>
                <div className="form_block">
                <div className="form_block__title">Выберите методы доставки (Можно отметить несколько) <span className='required'>*</span></div>
                    <form className='form_block__radio-group'>
                        <label for="Самовывоз">
                            <input 
                            checked={delivMethod?.includes("Самовывоз")}
                             onChange={checkboxFormMethodDelChange} className='form_block__radio-group-item' type="checkbox" id="Самовывоз" name="delivMethod" value="Самовывоз"/>Самовывоз
                        </label>
                        <label for="Курьером в пределах города">
                            <input 
                            onClick={() => setShowDeliveryBlock(!showDeliveryBlock)}
                            checked={delivMethod?.includes("Курьером в пределах города")}
                             onChange={checkboxFormMethodDelChange} className='form_block__radio-group-item' type="checkbox" id="Курьером в пределах города" name="delivMethod" value="Курьером в пределах города"/>Курьером в пределах города
                        </label>
                        <label for="Транспортной компанией">
                            <input 
                            checked={delivMethod?.includes("Транспортной компанией")}
                             onChange={checkboxFormMethodDelChange} className='form_block__radio-group-item' type="checkbox" id="Транспортной компанией" name="delivMethod" value="Транспортной компанией"/>Транспортной компанией
                        </label>
                        <label for="Автобусом">
                            <input 
                            checked={delivMethod?.includes("Автобусом")}
                             onChange={checkboxFormMethodDelChange} className='form_block__radio-group-item' type="checkbox" id="Автобусом" name="delivMethod" value="Автобусом"/>Автобусом
                        </label>
                        <label for="Почтой России">
                            <input 
                            checked={delivMethod?.includes("Почтой России")}
                             onChange={checkboxFormMethodDelChange} className='form_block__radio-group-item' type="checkbox" id="Почтой России" name="delivMethod" value="Почтой России"/>Почтой России
                        </label>
                        <label>
                            <input 
                            checked={delivMethod?.includes(anotherMethodDel)}
                             onChange={checkboxFormMethodDelChange} onClick={() => setShowAnotherMethodDel(true)} className='form_block__radio-group-item' type="checkbox" name="delivMethod" value={anotherMethodDel} />Другое
                            {showAnotherMethodDel ?
                            <input defaultValue={anotherMethodDel} onChange={anotherMethodDelChangeCallback} placeholder='Ваш ответ' className="form_block__input" />
                            :null}
                        </label>
                    </form>
                    {/* <textarea
                        className="form-control text"
                        name="response"
                        value={userinfo.response}
                        placeholder="The checkbox values will be displayed here "
                        id="floatingTextarea2"
                        style={{ height: "150px" }}
                        onChange={checkboxFormMethodDelChange}
                    ></textarea> */}
                </div>

                {showDeliveryBlock ? 
                <div className="form_block">
                <div className="form_block__title">Настройте курьерскую доставку <span className='required'>*</span></div>
                <div className="form_block__radio-group-item">Доставка платная?</div>
                    <form className="form_block__radio-group" onChange={payDeliveryChange}>
                        <label>
                            <input onClick={() => setShowExtraPayDelivery(true)} checked={payDelivery === 'Да'} className='form_block__radio-group-item' type="radio" name="Да" value="Да"/>Да
                        </label>
                        <label>
                            <input onClick={() => setShowExtraPayDelivery(false)} checked={payDelivery === 'Нет'} className='form_block__radio-group-item' type="radio" name="Нет" value="Нет"/>Нет
                        </label>
                    </form>   
                    {showExtraPayDelivery ?
                    <div>
                        <div className="form_block__radio-group-item">Стоимость доставки по городу:</div>
                        <input type="number" defaultValue={priceDelivery} onChange={priceDeliveryChangeCallback} placeholder='₽' className="form_block__input" />
                        <div className="form_block__radio-group-item">Бесплатная доставка от:</div>
                        <input type="number" defaultValue={freeStage} onChange={freeStageChangeCallback} placeholder='₽' className="form_block__input" />
                    </div>
                    : null}
                </div>
                : null}

                <div className="form_block">
                <div className="form_block__title">Выберите методы оплаты <span className='required'>*</span></div>
                    <form className='form_block__radio-group'>
                        <label for="Картой онлайн">
                            <input checked={payMethod?.includes("Картой онлайн")} onChange={checkboxFormPayMethodChange} className='form_block__radio-group-item' type="checkbox" id="Картой онлайн" name="payMethod" value="Картой онлайн"/>Картой онлайн (интернет эквайринг)
                        </label>
                        <label for="Картой при получении">
                            <input checked={payMethod?.includes("Картой при получении")} onChange={checkboxFormPayMethodChange} className='form_block__radio-group-item' type="checkbox" id="Картой при получении" name="payMethod" value="Картой при получении"/>Картой при получении
                        </label>
                        <label for="Наличными при получении">
                            <input checked={payMethod?.includes("Наличными при получении")} onChange={checkboxFormPayMethodChange} className='form_block__radio-group-item' type="checkbox" id="Наличными при получении" name="payMethod" value="Наличными при получении"/>Наличными при получении
                        </label>
                        <label for="Оплата по счету (для юр. лиц)">
                            <input checked={payMethod?.includes("Оплата по счету (для юр. лиц)")} onChange={checkboxFormPayMethodChange} className='form_block__radio-group-item' type="checkbox" id="Оплата по счету (для юр. лиц)" name="payMethod" value="Оплата по счету (для юр. лиц)"/>Оплата по счету (для юр. лиц)
                        </label>
                        <label for="Перевод на карту по номеру телефона">
                            <input checked={payMethod?.includes("Перевод на карту по номеру телефона")} onClick={() => setShowMethodPayBlock(!showMethodPayBlock)} onChange={checkboxFormPayMethodChange} className='form_block__radio-group-item' type="checkbox" id="Перевод на карту по номеру телефона" name="payMethod" value="Перевод на карту по номеру телефона"/>Перевод на карту по номеру телефона
                        </label>
                        <label for="Счет на оплату">
                            <input checked={payMethod?.includes("Счет на оплату")} onChange={checkboxFormPayMethodChange} className='form_block__radio-group-item' type="checkbox" id="Счет на оплату" name="payMethod" value="Счет на оплату"/>Счет на оплату
                        </label>
                    </form>
                    {/* <textarea
                        className="form-control text"
                        name="response"
                        value={info.response}
                        placeholder="The checkbox values will be displayed here "
                        id="floatingTextarea2"
                        style={{ height: "150px" }}
                        onChange={checkboxFormPayMethodChange}
                    ></textarea> */}
                </div>
                {showMethodPayBlock ? 
                <div className="form_block">
                    <div className="form_block__title">Настройте перевод на карту по номеру телефона <span className='required'>*</span></div>
                    <div className="form_block__radio-group-item">Введите номер телефона или карты</div>
                    <input defaultValue={numberPhoneCard} onChange={numberPhoneCardChangeCallback} placeholder='Ваш ответ' className="form_block__input" />
                    <div className="form_block__radio-group-item">ФИО получателя</div>
                    <input defaultValue={nameOfMoneyReciever} onChange={nameOfMoneyRecieverChangeCallback} placeholder='Ваш ответ' className="form_block__input" />
                </div>
                : null}
                <div className="form_block">
                    <div className="form_block__title">Требуется ли интернет эквайринг для приёма оплат картой онлайн? Для этого вам потребуется заключить договор с банком. <b>(Если интернет эквайринг не требуется, просто пропустите данный шаг)</b></div>
                    <div className="form_block--img">
                        <img className='form_block__img' src={require("../../Assets/Form/bank.webp")} alt="logoShop2" />
                    </div>
                    <form className="form_block__radio-group" onChange={radioFormBankChange}>
                        <label>
                            <input checked={radioFormBank === 'ПСБ'} className='form_block__radio-group-item' type="radio" name="Банк" value="ПСБ"/>ПСБ
                        </label>
                        <label>
                            <input checked={radioFormBank === 'Сбербанк'} className='form_block__radio-group-item' type="radio" name="Банк" value="Сбербанк"/>Сбербанк
                        </label>
                        <label>
                            <input checked={radioFormBank === 'Юmoney'} className='form_block__radio-group-item' type="radio" name="Банк" value="Юmoney"/>Юmoney
                        </label>
                        <label>
                            <input checked={radioFormBank === 'Robokassa'} className='form_block__radio-group-item' type="radio" name="Банк" value="Robokassa"/>Robokassa
                        </label>
                        <label>
                            <input checked={radioFormBank === 'Paymaster'} className='form_block__radio-group-item' type="radio" name="Банк" value="Paymaster"/>Paymaster
                        </label>
                        {radioFormBank != '' ?
                        <div style={{textAlign: 'right'}} onClick={() => setRadioFormBank('')}>Отменить выбор</div>
                        : null}
                    </form>
                </div>
                <div className="form_block">
                        <div className="form_block__title">Согласитесь с политикой конфиденциальности и правилами предоставления услуг (<Link target="_blank" to='/privacy'>Политика конфиденциальности</Link>) (<Link target="_blank" to='/privacy'>Правила предоставления услуг</Link>) <span className='required'>*</span></div>
                        <form className='form_block__radio-group' onChange={checkboxAgreeChange}>
                            <label>
                                <input checked={checkBoxAgree === 'Я согласен'} className='form_block__radio-group-item' type="radio" name="Согласен" value="Я согласен"/>Я согласен(а) с политикой конфиденциальности и правилами предоставления услуг
                            </label>
                        </form>
                    </div>
                <div className="form_button-block">
                        <div>
                            <div style={{marginRight: '10px'}} onClick={() => backTo3Step()} className="form_button-block__next">Назад</div>
                            {checkAllInputs4Step() && checkDeliveryBlock() && !isFormSent && checkMethodPayBlock() ?
                            <div onClick={() => submitSend(name, phone, city, radioFormReg, nameOfCompany, inn, addressCompany, nameOfShop, nameOfLink, descriptionShop, radioFormBtnName, helloMsg, radioFormColor, anotherColor, aboutDel, aboutCompany, userinfo, info, anotherMethodDel, radioFormBank, checkBoxAgree, firstImg, secondImg, thirdImg, categoryChoose, ostatkiChoose, numberPhoneCard, nameOfMoneyReciever)} className="form_button-block__next form_button-block__next--send">Отправить</div>
                            :
                            <div onClick={() => notifCheck()} className="form_button-block__next--no-data">Отправить</div>
                            }
                        </div>
                        <div onClick={() => clearForm()} className="form_button-block__clear">Очистить форму</div>
                    </div>
                </div>
                : null}
            <NotificationContainer/>
        </div>
        </Container>
        </div>
    );
}

export default Form;