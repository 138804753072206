import React from 'react'
import './Modal.css';

const Modal = ({onPress}) => {
    return (
        <div className="wrap">
            <div className="modal js-modal">
                <div className="modal-image">
                    <img className='modal-image_check' src={require("../../Assets/Modal/check.svg").default} alt="checkIcon" />
                </div>
                <div className='h1'>Олично!</div>
                <div className='p'>Форма отправлена, вашу заявку рассмотрят в течении часа.</div>
                <button onClick={onPress} className="btn js-close">Закрыть</button>
            </div>
        </div>
    );
}

export default Modal;