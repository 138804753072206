import React from 'react'
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import './BMenu.css'

const BMenu = ({onPress}) => {
    return (
        <div className="bmenu">
            <HashLink onClick={onPress} to='/#demo' className="bmenu_item bmenu_item__link link">Telegram-демо</HashLink>
            <HashLink to="/#clients" onClick={onPress} className="bmenu_item bmenu_item__link link">Наши клиенты</HashLink>
            <HashLink to="/#cost" onClick={onPress} className="bmenu_item bmenu_item__link link">Стоимость</HashLink>
            <HashLink to="/#faq" onClick={onPress} className="bmenu_item bmenu_item__link link">ЧаВо</HashLink>
            <HashLink to="/#contact" onClick={onPress} className="bmenu_item bmenu_item__link link">Контакты</HashLink>
            <Link onClick={onPress} to="/privacy" className='bmenu_item bmenu_item__link link'>Политика конфиденциальности</Link>
            <Link onClick={onPress} to="/privacy" className='bmenu_item bmenu_item__link link'>Публичная оферта</Link>
        </div>
    );
}
export default BMenu;