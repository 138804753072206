import React, {useEffect} from 'react'
import Container from '../../Components/Container/Container';
import Title, { Subtitle } from '../../Components/Title/Title';
import './FastSale.css'

const FastSale = () => {
    useEffect(() => {
        var square = document.querySelector('.fast-sale_block-flex');
    
        var observer = new IntersectionObserver(entries => {
          entries.forEach(entry => {
            var entrySquare = entry.target.querySelector('.fast-sale_block-flex__img');
    
            if (entry.isIntersecting) {
              entrySquare.classList.add('fast-sale_block-flex__img--animation');
              return;
            }
    
            entrySquare.classList.remove('fast-sale_block-flex__img--animation');
          });
        });

        var observerCards = new IntersectionObserver(entries => {
            entries.forEach(entry => {
              var entrySquare = entry.target.querySelector('.fast-sale_block__badges');
      
              if (entry.isIntersecting) {
                entrySquare.classList.add('fast-sale_block__badges--animation');
                return;
              }
      
              entrySquare.classList.remove('fast-sale_block__badges--animation');
            });
          });
    
        observer.observe(square);
        observerCards.observe(square);

      }, []);
    return (
        <div className="fast-sale">
            <Title>Быстрый <span className='marker'>заказ</span> - быстрая <span className='marker'>продажа</span>!</Title>
            <Subtitle>Можно заказывать и оплачивать товары прямо в телеграме!</Subtitle>
            <div className="fast-sale_block">
                <Container>
                <div className="fast-sale_block-flex">
                    <img className='fast-sale_block-flex__img' src={require("../../Assets/FastSale/handphone.webp")} alt="handphone" />
                    <div className="fast-sale_block__badges">
                        <img className='fast-sale_block__badges-item' src={require("../../Assets/FastSale/robo.svg").default} alt="badge-item" />
                        <img className='fast-sale_block__badges-item' src={require("../../Assets/FastSale/psb.svg").default} alt="badge-item" />
                        <img className='fast-sale_block__badges-item' src={require("../../Assets/FastSale/sber.svg").default} alt="badge-item" />
                        <img className='fast-sale_block__badges-item' src={require("../../Assets/FastSale/yoo.svg").default} alt="badge-item" />
                        <img className='fast-sale_block__badges-item' src={require("../../Assets/FastSale/paymaster.svg").default} alt="badge-item" />
                        <img className='fast-sale_block__badges-item' src={require("../../Assets/FastSale/pay-box.svg").default} alt="badge-item" />
                        <img className='fast-sale_block__badges-item' src={require("../../Assets/FastSale/131.svg").default} alt="badge-item" />
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    );
}

export default FastSale;