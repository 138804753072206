import React from 'react';
import { HashLink } from 'react-router-hash-link';
import Container from '../../Components/Container/Container';
import Title from '../../Components/Title/Title';
import './CostTariff.css';

const CostTariffList = ({list}) => {
    return (
        <div className='cost-tariff__main-item-list'>
            {list.map((item, i) => (
                <div key={i} className='cost-tariff__main-item-list-item'>
                    <img className='cost-tariff__main-item-list-item-icon' src={require(`../../Assets/CostTariff/${item.icon}.svg`)} alt="icon" />
                    <div style={{color: item.color}} className='cost-tariff__main-item-list-item-title'>{item.title}</div>
                </div>
            ))}
    </div>
    );
}

export const CostTariff = () => {
    return (
        <Container>
            <div id='cost' className='cost-tariff'>
                <Title>Стоимость</Title>
                    <div className='cost-tariff__main'>
                        <div className='cost-tariff__main-item cost-tariff__main-item--m'>
                            <div className='cost-tariff__main-item-title'>Индивидуальное оформление</div>
                            <div className='cost-tariff__main-item-sale'>
                                <div className='cost-tariff__main-item-sale-prevcost'>49 990₽</div>
                                <div className='cost-tariff__main-item-sale-saletext'>Скидка <span className='cost-tariff__main-item-sale-saletext--underline'>20 000₽</span></div>
                            </div>
                            <div className='cost-tariff__main-item-price'>
                                <div style={{color: '#fff'}} className='cost-tariff__main-item-price-sum'>29 990₽</div>
                                <div style={{color: '#000', background: '#fff'}} className='cost-tariff__main-item-price-badge'>разово</div>
                            </div>
                            <div className='cost-tariff__main-item-date'>
                                ТОЛЬКО ДО 01.05.2024
                            </div>
                            <div style={{color: '#fff'}} className='cost-tariff__main-item-listname'>Создание под ключ:</div>
                            <CostTariffList list={[
                                {icon: 'white', title: 'Настройка бота и групп', color: "#fff"},
                                {icon: 'white', title: 'Настройка внешнего вида', color: "#fff"},
                                {icon: 'white', title: 'Настройка способов доставки', color: "#fff"},
                                {icon: 'white', title: 'Настройка способов оплаты', color: "#fff"},
                                {icon: 'white', title: 'Начальное наполнение товарами', color: "#fff"},
                            ]} />
                        </div>
                        <div className='cost-tariff__main-item'>
                            <div className='cost-tariff__main-item-title'>Ежемесячный платеж</div>
                            <div className='cost-tariff__main-item-block'>
                                <div className='cost-tariff__main-item-price'>
                                    <div style={{color: '#7EACFF'}} className='cost-tariff__main-item-price-sum'>2490₽</div>
                                    <div style={{color: '#fff', background: '#7EACFF'}} className='cost-tariff__main-item-price-badge'>в месяц</div>
                                </div>
                                <div className='cost-tariff__main-item-listname'>Что входит в оплату:</div>
                                <CostTariffList list={[
                                    {icon: 'blue', title: 'Лицензия', color: "#000"},
                                    {icon: 'blue', title: 'Аренда серверов', color: "#000"},
                                    {icon: 'blue', title: 'Техподдержка', color: "#000"},
                                ]} />
                            </div>
                        </div>
                    </div>
                <HashLink to="/#contact" className="cost-tariff__btn animation link">Создать магазин</HashLink>
            </div>
        </Container>
    );
}