import React, {useEffect} from 'react'
import Container from '../../Components/Container/Container';
import Title from '../../Components/Title/Title';
import './ControlShop.css'
import '../DeliveryPayment/DeliveryPayment.css'

const ControlShop = () => {

    useEffect(() => {
        var square = document.querySelector('.csb');
    
        var observer = new IntersectionObserver(entries => {
          entries.forEach(entry => {
            var entrySquare = entry.target.querySelector('.control-shop_phones__img');
    
            if (entry.isIntersecting) {
              entrySquare.classList.add('control-shop_phones__img--animation');
              return;
            }
    
            entrySquare.classList.remove('control-shop_phones__img--animation');
          });
        });
    
        observer.observe(square);

        var observerCards = new IntersectionObserver(entries => {
            entries.forEach(entry => {
              var entrySquare = entry.target.querySelector('.control-shop_cards');
      
              if (entry.isIntersecting) {
                entrySquare.classList.add('control-shop_two-cards__item--animation');
                return;
              }
      
              entrySquare.classList.remove('control-shop_two-cards__item--animation');
            });
          });
      
          observerCards.observe(square);
      }, []);

   return (
    <div className="control-shop">
        <Container>
            <Title>Управляйте своим <span className='marker'>магазином</span> с телефона!</Title>
            <div className="control-shop_block csb">
                <div className="control-shop_cards">
                        <div className="control-shop_two-cards__item">
                            <img className='control-shop_two-cards__item-check' src={require("../../Assets/ControlShop/check.svg").default} alt="check_img" />
                            <div className="control-shop_two-cards__item-title">Категории</div>
                            <div className="control-shop_two-cards__item-subtitle">Добавляйте и изменяйте категории товаров</div>
                        </div>
                        <div className="control-shop_two-cards__item">
                            <img className='control-shop_two-cards__item-check' src={require("../../Assets/ControlShop/check.svg").default} alt="check_img" />
                            <div className="control-shop_two-cards__item-title">Доступность</div>
                            <div className="control-shop_two-cards__item-subtitle">Скрывайте товары при отсутствии на складе.</div>
                        </div>
                        <div className="control-shop_two-cards__item">
                            <img className='control-shop_two-cards__item-check' src={require("../../Assets/ControlShop/check.svg").default} alt="check_img" />
                            <div className="control-shop_two-cards__item-title">Товары</div>
                            <div className="control-shop_two-cards__item-subtitle">Создавайте, редактируйте информацию о товарах и услугах</div>
                        </div>
                        <div className="control-shop_two-cards__item">
                            <img className='control-shop_two-cards__item-check' src={require("../../Assets/ControlShop/check.svg").default} alt="check_img" />
                            <div className="control-shop_two-cards__item-title">Контроль остатков</div>
                            <div className="control-shop_two-cards__item-subtitle">Доступность товаров под вашим контролем</div>
                        </div>
                        <div className="control-shop_two-cards__item">
                            <img className='control-shop_two-cards__item-check' src={require("../../Assets/ControlShop/check.svg").default} alt="check_img" />
                            <div className="control-shop_two-cards__item-title">Статистика</div>
                            <div className="control-shop_two-cards__item-subtitle">Просмотр статистики по заказам и клиентам</div>
                        </div>
                        <div className="control-shop_two-cards__item">
                            <img className='control-shop_two-cards__item-check' src={require("../../Assets/ControlShop/check.svg").default} alt="check_img" />
                            <div className="control-shop_two-cards__item-title">Рассылки</div>
                            <div className="control-shop_two-cards__item-subtitle">Отправляйте клиентам любые сообщения</div>
                        </div>
                </div>
                <div className="control-shop_phones">
                    <img className='control-shop_phones__img' src={require("../../Assets/ControlShop/two-phones.webp")} alt="shop-phones" />
                </div>
            </div>
        </Container>
    </div>
   ); 
}

export default ControlShop;