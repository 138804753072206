import React, { useState } from 'react'
import { useWindowDimensions } from '../../App';
import BMenu from '../BMenu/BMenu';
import Container from '../Container/Container';
import {Link} from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import './Header.css'

const Header = () => {
    const { width } = useWindowDimensions();
    const [showBMenu, setShowBMenu] = useState(false);

    const btnChangeHandler = () => {
        if(showBMenu === true) {
           return <div onClick={() => setShowBMenu(!showBMenu)} className="burger_btn"><img className='burger_icon' src={require("../../Assets/close.svg").default} alt="bg-ico"></img></div>;
        }
        return <div onClick={() => setShowBMenu(!showBMenu)} className="burger_btn"><img className='burger_icon' src={require("../../Assets/burger-menu.svg").default} alt="bg-ico"></img></div>;
     }
    
    return (
        <div id="header" className="header">
        {showBMenu ? <BMenu onPress={() => setShowBMenu(false)} /> : null}
            <Container>
                <div className="header_wrapper">
                    <Link className='header_wrap-logo' to="/"><img className="header_logo" src={require('../../Assets/Logo/Logo TGMALL.svg').default} alt="logo" /></Link>
                    <div className="header_links__wrapper">
                            {/* <a href="tel:89785570005" className="header_wrapper-links__item-call link"><img width={20} style={{marginRight: '5px'}} src={require('../../Assets/Icons/phone.svg').default} alt="phone" /> Позвонить</a> */}
                        {width <= 800 ? btnChangeHandler() : 
                            <div className="header_wrapper-links">
                                <HashLink to='/#demo' className="header_wrapper-links__item link">Telegram-демо</HashLink>
                                <HashLink to="/#clients" className="header_wrapper-links__item link">Наши клиенты</HashLink>
                                <HashLink to="/#cost" className="header_wrapper-links__item link">Стоимость</HashLink>
                                <HashLink to="/#faq" className="header_wrapper-links__item link">ЧаВо</HashLink>
                                <HashLink to="/#contact" className="header_wrapper-links__item link">Контакты</HashLink>
                            </div>
                        }
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default Header;