import React from 'react'
import Footer from '../../Components/Footer/Footer';
import Header from '../../Components/Header/Header';
import './Oferta.css'

const Oferta = () => {
    return (
        <div className="oferta">
        ОФЕРТА
        </div>
    );
}

export default Oferta;