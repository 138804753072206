import React from 'react'
import Container from '../Container/Container';
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import './Footer.css'

const Footer = () => {
    return (
        <div className="footer">
            <Container>
                <img className='footer_logo' src={require("../../Assets/Logo/Logo TGMALL.svg").default} alt="Logo-TGMALL" />
                <div className="footer_links">
                <div className="footer_link__item">
                        <img className='footer_link__item-hashtag' src={require("../../Assets/Footer/hastag.svg").default} alt="hastag" />
                        <HashLink to='/#demo' className='footer_link__item-text link'>Telegram-демо</HashLink>
                    </div>
                    <div className="footer_link__item">
                        <img className='footer_link__item-hashtag' src={require("../../Assets/Footer/hastag.svg").default} alt="hastag" />
                        <HashLink to="/#clients" className='footer_link__item-text link'>Наши клиенты</HashLink>
                    </div>
                    <div className="footer_link__item">
                        <img className='footer_link__item-hashtag' src={require("../../Assets/Footer/hastag.svg").default} alt="hastag" />
                        <HashLink to="/#cost" className='footer_link__item-text link'>Стоимость</HashLink>
                    </div>
                    <div className="footer_link__item">
                        <img className='footer_link__item-hashtag' src={require("../../Assets/Footer/hastag.svg").default} alt="hastag" />
                        <HashLink to='/#faq' className='footer_link__item-text link'>ЧаВо</HashLink>
                    </div>
                    <div className="footer_link__item">
                        <img className='footer_link__item-hashtag' src={require("../../Assets/Footer/hastag.svg").default} alt="hastag" />
                        <HashLink to="/#contact" className='footer_link__item-text link'>Контакты</HashLink>
                    </div>
                    <div className="footer_link__item">
                        <img className='footer_link__item-hashtag' src={require("../../Assets/Footer/hastag.svg").default} alt="hastag" />
                        <Link to="/privacy" className='footer_link__item-text link'>Политика конфиденциальности</Link>
                    </div>
                    <div className="footer_link__item">
                        <img className='footer_link__item-hashtag' src={require("../../Assets/Footer/hastag.svg").default} alt="hastag" />
                        <Link to="/privacy" className='footer_link__item-text link'>Публичная оферта</Link>
                    </div>
                </div>
                <span style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>ООО Драйвери ИНН 2352055816</span>
            </Container>
        </div>
    );
}

export default Footer;