import React, {useEffect} from 'react'
import Container from '../../Components/Container/Container';
import Title from '../../Components/Title/Title';
import './Customization.css'

const CustomizationCard = ({items}) => {
    useEffect(() => {
        var square = document.querySelector('.customization_block');
    
        var observer = new IntersectionObserver(entries => {
          entries.forEach(entry => {
            var entrySquare = entry.target.querySelector('.customization_block-items');
    
            if (entry.isIntersecting) {
              entrySquare.classList.add('customization_block-items--animation');
              return;
            }
    
            entrySquare.classList.remove('customization_block-items--animation');
          });
        });
    
        observer.observe(square);
      }, []);
    return (
        <div className="customization_block-items">
            {items.map((item, index) => (
                <div key={index} className="customization_block-items__item">
                    <div className="customization_block-items__item-circle">
                        <img className='customization_block-items__item-circle--icon' src={require(`../../Assets/Icons/CustomizationIcons/${item.icon}.svg`)} alt="circle_img" />
                    </div>
                    <div className="customization_block-items__item-text">
                        {item.title}
                    </div>
                </div>
            ))}
        </div>
    );
}

const Customization = () => {
    return (
        <div className="customization">
                <Title>Индивидуальное <span className='marker'>оформление</span></Title>
                <div className="customization_block">
                    <Container>
                        <CustomizationCard items={[
                            {
                                icon: 'Аа',
                                title: 'Ваш логотип и название'
                            },
                            {
                                icon: 'color',
                                title: 'Используйте фирменные цвета в вашем магазине'
                            },
                            {
                                icon: 'categories',
                                title: 'Добавляйте нужные для вас категории'
                            },
                            {
                                icon: 'shop-items',
                                title: 'Наполните витрину товарами с подробным описанием и фото'
                            },
                        ]} />
                    </Container>
                </div>
            </div>
    );
}

export default Customization;