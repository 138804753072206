import React, {useEffect} from 'react'
import Container from '../../Components/Container/Container';
import Title from '../../Components/Title/Title';
import './OrderInfo.css'

const OrderInfo = () => {
    useEffect(() => {
        var square = document.querySelector('.order-info_block');
    
        var observer = new IntersectionObserver(entries => {
          entries.forEach(entry => {
            var entrySquare = entry.target.querySelector('.order-info_block__img');
    
            if (entry.isIntersecting) {
              entrySquare.classList.add('order-info_block__img--animation');
              return;
            }
    
            entrySquare.classList.remove('order-info_block__img--animation');
          });
        });
    
        observer.observe(square);

        var square = document.querySelector('.order-info_block');
    
        var observerBlock = new IntersectionObserver(entries => {
          entries.forEach(entry => {
            var entrySquare = entry.target.querySelector('.order-info_block-numbers');
    
            if (entry.isIntersecting) {
              entrySquare.classList.add('order-info_block-numbers--animation');
              return;
            }
    
            entrySquare.classList.remove('order-info_block-numbers--animation');
          });
        });
    
        observerBlock.observe(square);
      }, []);
    return (
        <div className="order-info">
            <Container>
                <Title>Информация о <span className='marker'>заказах</span></Title>
                <div className="order-info_block">
                    <img className='order-info_block__img' src={require("../../Assets/OrderInfo/phone.webp")} alt="phone" />
                    <div className="order-info_block-numbers">
                        <div className="order-info_block-numbers__item">
                            <div className="number_gradient--right">
                                <div className="circle_number">
                                    01
                                </div>
                            </div>
                            <div className="order_info__text-block">
                                Все данные о заказах приходят в персональный чат
                            </div>
                        </div>
                        <div className="order-info_block-numbers__item">
                                <div className="order_info__text-block order_info__text-block--left">
                                    Система уведомлений об успешной оплате заказа, с контактами заказчика
                                </div>
                                <div className="number_gradient--left">
                                    <div className="circle_number">
                                        02
                                    </div>
                                </div>
                        </div>
                        <div className="order-info_block-numbers__item">
                        <div className="number_gradient--right">
                                <div className="circle_number">
                                    03
                                </div>
                            </div>
                            <div className="order_info__text-block">
                                Быстрая обработка заказа и связь с клиентом
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default OrderInfo;