import React, { useRef, useCallback, useEffect } from 'react';
import Container from '../../Components/Container/Container';
import Title from '../../Components/Title/Title';
import LightGallery from 'lightgallery/react';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import './FAQ.css';

export const FAQ = () => {
    const lightGallery = useRef(null);

    const onInit = useCallback((detail) => {
        if (detail) {
            lightGallery.current = detail.instance;
        }
    }, []);

    useEffect(() => {
        lightGallery.current.refresh();
    }, []);

    return (
        <div id='faq' className='faq'>
            <Container>
                <Title>Вопросы и ответы</Title>
                <div className='faq__items'>
                    <details className='faq__items-item'>
                        <summary className='faq__items-item-title'><div className='faq__items-item-title--m'>В чем отличие Tgmall от обычного магазина в чат-ботах?</div></summary>
                        <div className='faq__items-item-row'>
                            <LightGallery
                                onInit={onInit}
                                speed={500}
                                mobileSettings
                                showCloseIcon={true}
                                controls={true}
                                download={false}
                                elementClassNames="faq__items-item-row-gallery"
                                plugins={[lgThumbnail]}>
                                <a className='faq__items-item-row-gallery-link' href={require(`../../Assets/FAQ/simple.webp`)}>
                                    <div><img className='faq__items-item-row-gallery-link-icon' src={require("../../Assets/FAQ/zoom.svg").default} /></div>
                                    <img className='faq__items-item-row-gallery-link-img' alt="Обычный бот telegram" src={require(`../../Assets/FAQ/simple.webp`)} />
                                </a>
                            </LightGallery>
                            <ul className='faq__items-item-title-list'>
                                <li className='faq__items-item-title-list-item'>В обычных чат-ботах интерфейс магазина выглядит крайне сложно для восприятия пользователями, что уменьшает вероятность покупки товаров.</li>
                            </ul>
                        </div>
                        <div className='faq__items-item-row'>
                            <LightGallery
                                onInit={onInit}
                                speed={500}
                                mobileSettings
                                showCloseIcon={true}
                                controls={true}
                                download={false}
                                elementClassNames="faq__items-item-row-gallery"
                                plugins={[lgThumbnail]}>
                                <a className='faq__items-item-row-gallery-link' href={require(`../../Assets/FAQ/tgmall.webp`)}>
                                    <div><img className='faq__items-item-row-gallery-link-icon' src={require("../../Assets/FAQ/zoom.svg").default} /></div>
                                    <img className='faq__items-item-row-gallery-link-img' alt="Обычный бот telegram" src={require(`../../Assets/FAQ/tgmall.webp`)} />
                                </a>
                            </LightGallery>
                            <ul className='faq__items-item-title-list'>
                                <li className='faq__items-item-title-list-item'>Интерфейс TGMALL выглядит как мобильная версия классического интернет магазина или мобильного приложения.</li>
                            </ul>
                        </div>
                    </details>

                    <details className='faq__items-item'>
                        <summary className='faq__items-item-title'><div className='faq__items-item-title--m'>В чем преимущества Tgmall по сравнению с классическим интернет-магазином?</div></summary>
                        <ul className='faq__items-item-title-list'>
                            <li className='faq__items-item-title-list-item'>
                                <b style={{color: '#007AFF'}}>Стоимость создания</b> обычного интернет-магазина редко бывает дешевле 100 тысяч рублей. Решение на базе TGMALL обойдется вам значительно дешевле.
                            </li>
                            <li className='faq__items-item-title-list-item'>
                                <b style={{color: '#007AFF'}}>Сложность создания</b> обычные интернет магазины требуют непосредственного участия заказчика в процессе создания. Сложность выбора дизайна, системы управления сайтом, навыки управления интернет-магазином: все это предстоит пройти заказчику чтобы создать более-менее приемлемый веб-сайт.
                            </li>
                            <div className='faq__items-item-title-list-item'>
                                TGMALL - это готовое решение с единым дизайном, адаптирующееся под потребности вашего бизнеса. Наши специалисты возьмут на себя все технические задачи по открытию инернет-магазина в телеграме. 
                            </div>
                            <li className='faq__items-item-title-list-item'>
                                <b style={{color: '#007AFF'}}>Сроки создания</b> обычных интернет-магазинов составляют от 30 дней до 12 месяцев. 
                            </li>
                            <div className='faq__items-item-title-list-item'>
                                С командой TGMALL вы откроете магазин за 1 день!
                            </div>
                            <li className='faq__items-item-title-list-item'>
                                <b style={{color: '#007AFF'}}>Сотрудники.</b> Не нужны сео-специалисты, программисты, webмастера в штате. Соответственно не требуется платить зарплаты. С TGMALL вам нужен только один ваш штатный сотрудник - менеджер или продавец, который будет добавлять товары и работать с клиентами.
                            </li>
                        </ul>
                    </details>

                    <details className='faq__items-item'>
                        <summary className='faq__items-item-title'><div className='faq__items-item-title--m'>В чем преимущества TGMALL по сравнению с мобильными приложениями?</div></summary>
                        <ul className='faq__items-item-title-list'>
                            <li className='faq__items-item-title-list-item'><b style={{color: '#007AFF'}}>Стоимость и сроки разработки.</b> Разработка мобильных приложений - крайне затратное мероприятие, составляет от нескольких сотен тысяч до нескольких миллионов рублей, а иногда и десятков миллионов. Создание приложений занимает очень много времени, так как у каждой платформы свои языки программирования и требования. Такой инструмент могут позволить себе, в большей части, крупные компании.</li>
                            <div className='faq__items-item-title-list-item'>TGMALL напротив, позволяет с минимальными финансовыми и временными вложениями, октрыть интернет магазин сразу для всех платформ.</div>
                            <li className='faq__items-item-title-list-item'><b style={{color: '#007AFF'}}>Публикация приложений.</b> Мобильные приложения необходимо публиковать на разных платформах в фирменных магазинах. Если приложение не соответствует правилам iOS или Android, оно будет удалено.</li>
                            <div className='faq__items-item-title-list-item'>С продуктом TGMALL не требуется публикация в магазинах приложений и зависеть от их правил. </div>
                            <li className='faq__items-item-title-list-item'><b style={{color: '#007AFF'}}>Подержка и обновление.</b> Apple и Google тщательно следят за опубликованными приложениями, и требуют от разработчиков своевременного обновления приложений. Если разработчики не принимают мер по обновлению своих продуктов под новые версии систем, приложения удаляются.</li>
                            <div className='faq__items-item-title-list-item'>С TGMALL не требуется обновление магазинов под новые версии систем.</div>

                        </ul>
                    </details>

                    <details className='faq__items-item'>
                        <summary className='faq__items-item-title'><div className='faq__items-item-title--m'>На каких платформах работает TGMALL?</div></summary>
                        <ul className='faq__items-item-title-list'>
                            <li className='faq__items-item-title-list-item'>TGMALL создан для работы в фирменных приложениях Telegram на платформах iOS, Android, Windows, macOs и Linux.</li>
                        </ul>
                    </details>

                    <details className='faq__items-item'>
                        <summary className='faq__items-item-title'><div className='faq__items-item-title--m'>У меня нет времени заниматься открытием интернет-магазина, как быть?</div></summary>
                        <ul className='faq__items-item-title-list'>
                            <li className='faq__items-item-title-list-item'>Не проблема, специалисты TGMALL возьмут на себя всю работу по созданию и настройке вашего интернет магазина. Для этого оставьте заявку <a href='/#contact' className='faq__items-item-title-list-item--link'>ЗДЕСЬ</a></li>
                        </ul>
                    </details>

                    <details className='faq__items-item'>
                        <summary className='faq__items-item-title'><div className='faq__items-item-title--m'>А как раскручивать TGMALL и привлекать клиентов?</div></summary>
                        <ul className='faq__items-item-title-list'>
                            <li className='faq__items-item-title-list-item'>Продвижение магазина в Telegram не имеет существенных отличий от раскрутки групп в VK или аккаунтов Instagram.</li>
                            <li className='faq__items-item-title-list-item'>Команда TGMALL может взять на себя всю работу по продвижению вашего магазина.</li>
                        </ul>
                    </details>

                </div>
            </Container>
        </div>
    );
}