import React from 'react'
import './Title.css'

const Title = ({children}) => {
    return (
        <div className="title">
            {children}
        </div>
    );
}

export const Subtitle = ({children}) => {
    return (
        <div className="subtitle">
            {children}
        </div>
    );
}

export default Title;