import React, {useState, useCallback, useRef} from 'react'
import Container from '../../Components/Container/Container';
import Title from '../../Components/Title/Title';
import validator from 'validator' 
import HCaptcha from "@hcaptcha/react-hcaptcha";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import Modal from '../../Components/Modal/Modal';

import 'react-notifications/lib/notifications.css';
import './Connect.css'

const Connect = () => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [isNameValidate, setIsNameValidate] = useState(false);
    const [token, setToken] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const captchaRef = useRef(null);
    const inputRefPhone = useRef(null);
    const inputRefName = useRef(null);

    // info@drivery.org
    const SendMail = (name, phone) => {
        window.Email.send({
            SecureToken : "43a22622-b674-4744-99f2-c67be48028b8",
            To : 'info@drivery.org',
            From : "tgmall@tgmall.ru",
            Subject : "🔵 TGMALL (Заявка через форму TGMALL)",
            Body : `<h1 style="text-align: center">TGMALL</h1>
            <p style="text-align: center">Заявка на сайте <a href="https://tgmall.ru/">tgmall.ru</a></p>
            <table style="border-collapse: collapse; width: 100%;">
            <tr>
              <td style="padding: 10px; border: #e9ecef solid 1px;">Имя:</td>
              <td style="padding: 10px; border: #e9ecef solid 1px;"><strong>${name}</strong></td>
            </tr>
            <tr>
              <td style="padding: 10px; border: #e9ecef solid 1px;">Номер телефона:</td>
              <td style="padding: 10px; border: #e9ecef solid 1px;"><a href="tel:${phone}">${phone}</a></td>
            </tr>
          </table>`
        }).then(
            message => console.log(message)
        );
    }

    // FUNCTIONS
    const onExpire = () => {
        console.log("hCaptcha Token Expired");
      };
    
    const onError = (err) => {
        console.log(`hCaptcha Error: ${err}`);
    };

    let isValidPhoneNumber;
    const validatePhoneNumber = (phone) => {
        isValidPhoneNumber = validator.isMobilePhone(phone)
        if (isValidPhoneNumber == false) {
            createNotification('phone');
        }
        return (isValidPhoneNumber)
    }

    var re = /[^[A-Za-zА-Яа-яёЁ]+(?:[-'\s][A-Za-zА-Яа-яёЁ]+)*$]/;
    const checkName = (name) => {
        if (name.length > 35) {
            createNotification('nameLimit');
            return false;
        }
        else if (re.test(name)) {
            createNotification('name');
            return false;
        }
        setIsNameValidate(true);
        return true;
    }

    const checkAllInputs = () => {
        if (phone != "" && name != "" && token != null) {
            return true;
        }
        return false;
    }

    const createNotification = (type) => {
          switch (type) {
            case 'phone':
              NotificationManager.info('Неправильно введен номер!');
              break;
            case 'success':
              NotificationManager.success('Форма отправлена!');
              break;
            case 'nameLimit':
              NotificationManager.info('Имя не должно превышать больше 35 символов!');
              break;
            case 'name':
              NotificationManager.info('Неправильно введено Имя!');
              break;
            case 'checkForm':
              NotificationManager.info('Заполните имя и номер телефона!');
              break;
        }
    };
    
    // CALLBACKS
    const phoneChangeCallback = useCallback(
        (e) => {
            setPhone(e.target.value.replace(/[^\d]/g, ''));
        }, []
    );

    const nameChangeCallback = useCallback(
        (e) => {
            setName(e.target.value);
        }, []
    );

    const submit = (name, phone) => {
        if (validatePhoneNumber(phone) && checkName(name) && token != null) {
            SendMail(name, phone);
            setPhone('');
            setName('');
            setToken(null);
            inputRefPhone.current.value = "";
            inputRefName.current.value = "";
            captchaRef.current.resetCaptcha();
            setShowModal(true);
            return true;
        }
        return false;
    }

    return (
        <div id='contact' className="connect">
        {showModal ? <Modal onPress={() => setShowModal(false)} /> : null}
            <Title>Оставить заявку</Title>
            <Container>
            <div className="connect_block__flex">
                <form method="post" enctype="text/plain" onSubmit={() => {submit(name, phone)}} className="connect_block">

                    <div className="connect_block__item">
                        <div className="connect_block__item-badge">Имя</div>
                        <input type="text" name="name" ref={inputRefName} defaultValue={name} onChange={nameChangeCallback} placeholder='Например: Иван Иванович' className="connect_block__item-input" />
                    </div>

                    <div style={{border: isValidPhoneNumber ? "2px solid green" : "2px solid #7EACFF"}} className="connect_block__item">
                        <div className="connect_block__item-badge">Телефон</div>
                        <input 
                        name="phone"
                        type="tel" 
                        ref={inputRefPhone}
                        // onInput={(e) => e.target.value = e.target.value.slice(0, 13)}
                        defaultValue={phone} 
                        onChange={phoneChangeCallback} 
                        placeholder='+0(000)000-00-00' 
                        className="connect_block__item-input"
                         />
                    </div>
                    <div className="captcha">
                        <HCaptcha
                            sitekey="56340533-4f13-4b67-9e8b-f2e9b00ef4dd"
                            onVerify={setToken}
                            ref={captchaRef}
                            onError={onError}
                            onExpire={onExpire}
                        />
                    </div>

                    {checkAllInputs() ?  
                    <div className="connect_block__btn" type="submit" onClick={() => {submit(name, phone);}}>Отправить</div>
                    :  
                    <div onClick={() => createNotification("checkForm")} className="connect_block__btn" style={{background: "#DFDFDF"}} >Отправить</div>
                    }
                </form>
                <NotificationContainer/>
                </div>
            </Container>
        </div>
    );
}
export default Connect;