import React, {useEffect} from 'react'
import Container from '../../Components/Container/Container';
import Title from '../../Components/Title/Title';
import './DeliveryPayment.css'

const DeliveryPaymentItems = ({items}) => {
    return (
        <div className="delivery-payment_items">
            {items.map((item, index) => (
                <div key={index} className={`delivery-payment_items__item ${item.class}`}>
                    <img className='delivery-payment_items__item-img' src={require(`../../Assets/DeliveryPayment/Icons/${item.icon}.svg`)} alt="icon-deliverypayment" />
                    <div className="delivery-payment_items__item-title">{item.title}</div>
                </div>
            ))}
        </div>
    );
}

const DeliveryPayment = () => {
    useEffect(() => {
        var square = document.querySelector('.delivery-payment_block-wrapper');
    
        var observer = new IntersectionObserver(entries => {
          entries.forEach(entry => {
            var entrySquare = entry.target.querySelector('.delivery-payment_phones__img');
    
            if (entry.isIntersecting) {
              entrySquare.classList.add('delivery-payment_phones__img--animation');
              return;
            }
    
            entrySquare.classList.remove('delivery-payment_phones__img--animation');
          });
        });
    
        observer.observe(square);

        var observerBlock = new IntersectionObserver(entries => {
            entries.forEach(entry => {
              var entrySquare = entry.target.querySelector('.delivery-payment');
      
              if (entry.isIntersecting) {
                entrySquare.classList.add('delivery-payment_items--animation');
                return;
              }
      
              entrySquare.classList.remove('delivery-payment_items--animation');
            });
          });
      
          observerBlock.observe(square);
      }, []);
   return (
    <div className="control-shop">
        <Container>
            <Title>Доставляйте товары и принимайте оплаты от клиентов <span className='marker'>!</span></Title>
            <div className="control-shop_block delivery-payment_block-wrapper">
                    <div className="delivery-payment">
                        <div className="delivery-payment_block delivery-payment_block--margin">
                            <div className="delivery-payment_block__title">Доставляйте товары клиентам удобным способом:</div>
                            <DeliveryPaymentItems items={[
                                {
                                    icon: "curier",
                                    title: "Курьером  в пределах города"
                                },
                                {
                                    icon: "samovivoz",
                                    title: "Самовывоз"
                                },
                                {
                                    icon: "truck",
                                    title: "Транспортной компанией (СДЕК, Боксберри, DPD и т. д.)"
                                },
                                {
                                    icon: "bus",
                                    title: "Доставка автобусом"
                                },
                                {
                                    icon: "pochta",
                                    title: "Почтой россии",
                                    class: 'delivery-payment_items__item--last'
                                },
                            ]} />
                        </div>
                        <div className="delivery-payment_block delivery-payment_block--top-margin">
                        <div className="delivery-payment_block__title">Оплачивайте товары удобным способом:</div>
                        <DeliveryPaymentItems items={[
                                {
                                    icon: "card",
                                    title: "Картой онлайн (интернет эквайринг)"
                                },
                                {
                                    icon: "terminal",
                                    title: "Картой при получении"
                                },
                                {
                                    icon: "wallet",
                                    title: "Оплата наличными при получении"
                                },
                                {
                                    icon: "sbp",
                                    title: "Оплата переводом на карту по номеру телефона"
                                },
                                {
                                    icon: "check",
                                    title: "Счет на оплату",
                                    class: 'delivery-payment_items__item--last'
                                },
                            ]} />
                        </div>
                    </div>
                <div className="delivery-payment_phones">
                    <img className='delivery-payment_phones__img' src={require("../../Assets/DeliveryPayment/deliverypayment.webp")} alt="shop-phones" />
                </div>
            </div>
        </Container>
    </div>
   ); 
}

export default DeliveryPayment;