import React, { useRef } from 'react'
import Container from '../../Components/Container/Container';
import Title from '../../Components/Title/Title';
import Slider from "react-slick";
import './Clients.css'
import { useWindowDimensions } from '../../App';

const Clients = () => {
    const { width } = useWindowDimensions();
    const scrollToClients = useRef();

    var slide = 4;
    if (width <= 1070) {
        slide = 3;
    }
    if (width <= 800) {
        slide = 1;
    }

    var settings = {
        nextArrow: <img className='arrow' src={require('../../Assets/Clients/right-arrow.svg').default} alt="arrow" />,
        prevArrow: <img className='arrow' src={require('../../Assets/Clients/left-arrow.svg').default} alt="arrow" />,
        autoplay: true,
        autoplaySpeed: 3500,
        dots: false,
        draggable: true,
        infinite: true,
        speed: 500,
        slidesToShow: slide,
        slidesToScroll: 1
      };

    return (
        <div ref={scrollToClients} id='clients' className="clients">
            <Container>
                <Title>Наши клиенты</Title>
                <div className="clients_block">
                
                {width <= 800 ? null : <Slider {...settings}>
                <a href='https://t.me/Silartos_shop_bot' target="_blank" rel="noreferrer" className="carousel-slide_wrapper link">
                <div className="clients_item">
                        <img className="clients_item__img" src={require('../../Assets/Clients/silartos.webp')} alt="client_img" />
                        {/* <div className="clients_item-text">“Силартос” - Пекарня «Силартос»</div> */}
                    </div>
                </a>

                <a href='https://t.me/iHouseshopBot' target="_blank" rel="noreferrer" className="carousel-slide_wrapper link">
                    <div className="clients_item">
                        <img className="clients_item__img" src={require('../../Assets/Clients/ihouse.webp')} alt="client_img" />
                        {/* <div className="clients_item-text">“iHouse” - Техника Apple и аксессуары.</div> */}
                    </div>
                </a>

                <a href='https://t.me/rest_yangiyul_bot' target="_blank" rel="noreferrer" className="carousel-slide_wrapper link">
                    <div className="clients_item">
                        <img className="clients_item__img" src={require('../../Assets/Clients/yanigul.webp')} alt="client_img" />
                        {/* <div className="clients_item-text">“Янгиюль” - доставка еды.</div> */}
                    </div>
                </a>

                <a href='https://t.me/HaierAir_bot' target="_blank" rel="noreferrer" className="carousel-slide_wrapper link">
                    <div className="clients_item">
                        <img className="clients_item__img" src={require('../../Assets/Clients/haier.webp')} alt="client_img" />
                        {/* <div className="clients_item-text">“Haier” - Интернет магазин кондиционеров Haier.</div> */}
                    </div>
                </a>

                <a href='https://t.me/juicy_tgbot' target="_blank" rel="noreferrer" className="carousel-slide_wrapper link">
                    <div className="clients_item">
                        <img className="clients_item__img" src={require('../../Assets/Clients/juicy-x.webp')} alt="client_img" />
                        {/* <div className="clients_item-text">“iHouse” - Техника Apple и аксессуары.</div> */}
                    </div>
                </a>
                <a href='https://t.me/Ballbar_tgbot' target="_blank" rel="noreferrer" className="carousel-slide_wrapper link">
                    <div className="clients_item">
                        <img className="clients_item__img clients_item__img-full" src={require('../../Assets/Clients/balbar.webp')} alt="client_img" />
                        {/* <div className="clients_item-text">“iHouse” - Техника Apple и аксессуары.</div> */}
                    </div>
                </a>
                </Slider>}
                {width <= 800 ? 
                <Slider {...settings}>
                <div>
                <a href='https://t.me/Silartos_shop_bot' target="_blank" rel="noreferrer" className="carousel-slide_wrapper link">
                <div className="clients_item">
                        <img className="clients_item__img" src={require('../../Assets/Clients/silartos.webp')} alt="client_img" />
                        {/* <div className="clients_item-text">“Силартос” - Пекарня «Силартос»</div> */}
                    </div>
                </a>

                <a href='https://t.me/iHouseshopBot' target="_blank" rel="noreferrer" className="carousel-slide_wrapper link">
                    <div className="clients_item">
                        <img className="clients_item__img" src={require('../../Assets/Clients/ihouse.webp')} alt="client_img" />
                        {/* <div className="clients_item-text">“iHouse” - Техника Apple и аксессуары.</div> */}
                    </div>
                </a>

                <a href='https://t.me/rest_yangiyul_bot' target="_blank" rel="noreferrer" className="carousel-slide_wrapper link">
                    <div className="clients_item">
                        <img className="clients_item__img" src={require('../../Assets/Clients/yanigul.webp')} alt="client_img" />
                        {/* <div className="clients_item-text">“Янгиюль” - доставка еды.</div> */}
                    </div>
                </a>
                </div>
                <div>
                <a href='https://t.me/HaierAir_bot' target="_blank" rel="noreferrer" className="carousel-slide_wrapper link">
                    <div className="clients_item">
                        <img className="clients_item__img" src={require('../../Assets/Clients/haier.webp')} alt="client_img" />
                        {/* <div className="clients_item-text">“Haier” - Интернет магазин кондиционеров Haier.</div> */}
                    </div>
                </a>
                
                <a href='https://t.me/juicy_tgbot' target="_blank" rel="noreferrer" className="carousel-slide_wrapper link">
                    <div className="clients_item">
                        <img className="clients_item__img" src={require('../../Assets/Clients/juicy-x.webp')} alt="client_img" />
                        {/* <div className="clients_item-text">“iHouse” - Техника Apple и аксессуары.</div> */}
                    </div>
                </a>
                <a href='https://t.me/Ballbar_tgbot' target="_blank" rel="noreferrer" className="carousel-slide_wrapper link">
                    <div className="clients_item">
                        <img className="clients_item__img" src={require('../../Assets/Clients/balbar.webp')} alt="client_img" />
                        {/* <div className="clients_item-text">“iHouse” - Техника Apple и аксессуары.</div> */}
                    </div>
                </a>
                </div>
                </Slider>
                 : null }
            </div>
            </Container>
        </div>
    );
}

export default Clients;